import { motion } from "framer-motion";
import type { MotionConfig } from "../types";
import { useState } from "react";

interface HexagonProps extends MotionConfig {
  color: string;
  onClick: () => void;
  progress: number;
  name: string;
  index?: number;
}

export const Hexagon = ({
  color,
  onClick,
  progress = 0,
  name,
  index = 0,
}: HexagonProps) => {
  const [isHovered, setIsHovered] = useState(false);
  const validProgress = Math.max(0, Math.min(100, progress));

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, scale: 0.8 }}
      transition={{ delay: index * 0.1 }}
      className="w-fit relative"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isHovered && (
        <motion.div
          initial={{ opacity: 0, y: 5 }}
          animate={{ opacity: 1, y: 0 }}
          className="absolute -top-8 left-1/2 -translate-x-1/2 whitespace-nowrap text-sm text-white bg-black/75 px-2 py-1 rounded"
        >
          {name}
        </motion.div>
      )}
      <motion.div
        whileTap={{
          scale: 0.95,
          transition: {
            duration: 0.2,
          },
        }}
        className="cursor-pointer flex items-center justify-center relative overflow-hidden w-12 h-12 text-xs shadow-lg"
        style={{
          clipPath:
            "polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)",
          transition: "all 0.3s ease-out",
        }}
        onClick={onClick}
      >
        <div className="absolute inset-0" style={{ backgroundColor: color }} />
        <div
          className="absolute inset-0 bg-gray-200/60"
          style={{
            height: `${100 - validProgress}%`,
            top: 0,
            transition: "height 0.3s ease-out",
          }}
        />
      </motion.div>
    </motion.div>
  );
};

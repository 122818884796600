import React, { useState, useMemo } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";

import { 
  BookOpen, 
  ArrowUpDown,
  AlertCircle,
  FileWarning,
  AlertOctagon
} from 'lucide-react';

import { Button } from '../../../ui/button';
import { useGetStudentLearningGapsQuery } from '../../schools/SchoolsApiSlice';

// Mock data
const mockData = {
  grades: ["All Grades", "Grade 8", "Grade 9", "Grade 10"],
  learningGaps: [
    {
      id: 1,
      concept: "Linear Equations",
      subject: "Mathematics",
      grade: "Grade 9",
      status: "Critical",
      mastery: 35,
    },
    {
      id: 2,
      concept: "Chemical Bonding",
      subject: "Chemistry",
      grade: "Grade 9",
      status: "Needs Attention",
      mastery: 55,
    },
    {
      id: 3,
      concept: "Force and Motion",
      subject: "Physics",
      grade: "Grade 8",
      status: "Needs Review",
      mastery: 65,
    },
    {
      id: 4,
      concept: "Cell Division",
      subject: "Biology",
      grade: "Grade 10",
      status: "Critical",
      mastery: 42,
    },
    {
      id: 5,
      concept: "Integration",
      subject: "Mathematics",
      grade: "Grade 10",
      status: "Needs Attention",
      mastery: 58,
    },
    {
      id: 6,
      concept: "Organic Chemistry",
      subject: "Chemistry",
      grade: "Grade 10",
      status: "Critical",
      mastery: 38,
    }
  ]
};

// Status Badge Component
const StatusBadge = ({ status }:any) => {
  const styles :any = {
    'Critical': 'bg-red-50 border-red-200 text-red-700',
    'Needs Attention': 'bg-yellow-50 border-yellow-200 text-yellow-700',
    'Needs Review': 'bg-orange-50 border-orange-200 text-orange-700'
  };

  return (
    <div className={`inline-flex items-center gap-1.5 rounded-full px-2.5 py-1 border ${styles[status]}`}>
      <AlertCircle size={14} />
      <span className="text-sm font-medium">{status}</span>
    </div>
  );
};

// Mastery Badge Component
const MasteryBadge = ({ value }:any) => {
  let colors;
  if (value >= 65) colors = "bg-yellow-50 border-yellow-200 text-yellow-700";
  else if (value >= 50) colors = "bg-orange-50 border-orange-200 text-orange-700";
  else colors = "bg-red-50 border-red-200 text-red-700";

  return (
    <div className={`inline-flex items-center rounded-full px-2.5 py-1 border ${colors}`}>
      <span className="text-sm font-medium">{value}%</span>
    </div>
  );
};

const EmptyState = () => (
  <div className="flex flex-col items-center justify-center p-12 text-center bg-gray-50/50 rounded-lg border border-dashed border-gray-200">
    <AlertOctagon className="w-12 h-12 text-gray-400 mb-3" />
    <h3 className="text-lg font-medium text-gray-900 mb-2">No Learning Gaps Found</h3>
    <p className="text-sm text-gray-500 max-w-sm">
      No learning gaps were found for the selected grade. Try selecting a different grade to view more gaps.
    </p>
  </div>
);

const ErrorState = () => (
  <div className="flex flex-col items-center justify-center p-12 text-center bg-red-50/50 rounded-lg border border-dashed border-red-200">
    <FileWarning className="w-12 h-12 text-red-400 mb-3" />
    <h3 className="text-lg font-medium text-red-900 mb-2">Unable to Load Data</h3>
    <p className="text-sm text-red-600 max-w-sm">
      We encountered an error while loading the learning gaps data. Please try again later.
    </p>
    <Button variant="outline" className="mt-4 text-red-600 border-red-200 hover:bg-red-50">
      Retry Loading
    </Button>
  </div>
);
const grades= ["All Grades", "Grade 6", "Grade 7", "Grade 8", "Grade 9", "Grade 10", "Grade 11", "Grade 12"];
const LearningGapsTable = ({ 
  isLoading = false,
  error = null,
  studentId,
  schoolId
}: {
  isLoading?: boolean;
  error?: any;
    studentId?: string;
  schoolId?: string;
}) => {
  const [selectedGrade, setSelectedGrade] = useState(grades[0]);
  const [sortBy, setSortBy] = useState('mastery');
  const [sortOrder, setSortOrder] = useState('asc');
  const { data, isLoading: isLoadingGaps, isError } = useGetStudentLearningGapsQuery({ studentId, schoolId });

  // const filteredAndSortedGaps = useMemo(() => {
  //   let filtered = data?.learningGaps;
  //   if (selectedGrade !== "All Grades") {
  //     filtered = filtered.filter(gap => gap.grade === selectedGrade);
  //   }
    
  //   return filtered?.sort((a, b) => {
  //     const multiplier = sortOrder === 'desc' ? -1 : 1;
  //     return (a[sortBy] - b[sortBy]) * multiplier;
  //   });
  // }, [selectedGrade, sortBy, sortOrder, data?.learningGaps]);
  const filteredAndSortedGaps = useMemo(() => {
    let filtered = [...(data?.learningGaps || [])];
    if (selectedGrade !== "All Grades") {
      filtered = filtered.filter(gap => gap.grade === selectedGrade);
    }
    
    return filtered.sort((a, b) => {
      const multiplier = sortOrder === 'desc' ? -1 : 1;
      return (a[sortBy] - b[sortBy]) * multiplier;
    });
 }, [selectedGrade, sortBy, sortOrder, data?.learningGaps]);

  const toggleSort = (field:any) => {
    if (sortBy === field) {
      setSortOrder(current => current === 'desc' ? 'asc' : 'desc');
    } else {
      setSortBy(field);
      setSortOrder('asc');
    }
  };

  if (isError) return <ErrorState />;
  if (isLoading) return <div>Loading...</div>;

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <Select value={selectedGrade} onValueChange={setSelectedGrade}>
            <SelectTrigger className="w-44">
              <SelectValue placeholder="Select Grade" />
            </SelectTrigger>
            <SelectContent>
              {data?.grades?.map((grade:any) => (
                <SelectItem key={grade} value={grade}>
                  {grade}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        
        <div className="text-sm text-gray-500">
          Showing {filteredAndSortedGaps?.length} learning gaps
        </div>
      </div>

      {filteredAndSortedGaps?.length > 0 ? (
        <div className="bg-white rounded-xl overflow-hidden border border-gray-100">
          <Table>
            <TableHeader>
              <TableRow className="border-none">
                <TableHead className="w-[35%] bg-gray-50/50 text-gray-600 font-medium py-4">
                  Concept
                </TableHead>
                <TableHead className="w-[20%] bg-gray-50/50 text-gray-600 font-medium py-4">
                  Subject
                </TableHead>
                <TableHead className="w-[25%] bg-gray-50/50 text-gray-600 font-medium py-4">
                  Status
                </TableHead>
                <TableHead 
                  className="w-[20%] bg-gray-50/50 text-gray-600 font-medium py-4 cursor-pointer hover:bg-gray-100/50"
                  onClick={() => toggleSort('mastery')}
                >
                  <div className="flex items-center justify-center gap-1">
                    Mastery
                    <ArrowUpDown size={14} />
                  </div>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {filteredAndSortedGaps.map((gap, index) => (
                <TableRow
                  key={gap.id}
                  className={`
                    hover:bg-red-50/10 transition-colors duration-150
                    ${index !== filteredAndSortedGaps.length - 1 ? "border-b border-gray-100" : ""}
                  `}
                >
                  <TableCell className="py-4">
                    <div className="flex items-center gap-3">
                      <div className="w-10 h-10 rounded-lg bg-red-100/50 flex items-center justify-center">
                        <BookOpen size={20} className="text-red-600" />
                      </div>
                      <span className="font-medium text-gray-900">
                        {gap.concept}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell className="py-4">
                    <div className="flex items-center gap-2">
                      <span className="text-gray-600">{gap.subject}</span>
                      {selectedGrade === "All Grades" && (
                        <span className="text-gray-400 text-sm">({gap.grade})</span>
                      )}
                    </div>
                  </TableCell>
                  <TableCell className="py-4">
                    <StatusBadge status={gap.status} />
                  </TableCell>
                  <TableCell className="py-4 text-center">
                    <MasteryBadge value={gap.mastery} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        <EmptyState />
      )}
    </div>
  );
};

export default LearningGapsTable;
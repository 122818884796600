import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '../../../ui/tooltip';
import { HelpCircle } from 'lucide-react';
import React from 'react';


const InfoTooltip = ({ description, trend }:any) => {
    return (
      <TooltipProvider delayDuration={200}>
        <Tooltip>
          <TooltipTrigger asChild>
            <button className="p-1 hover:bg-slate-100 translate-x-[14px] rounded-full transition-colors">
              <HelpCircle className="w-5 h-5 text-slate-400" />
            </button>
          </TooltipTrigger>
          <TooltipContent 
            className="bg-white border border-slate-200 shadow-lg p-3 rounded-lg max-w-xs break-words"
            sideOffset={5}
          >
            <p className="text-sm text-slate-950">{description}</p>
            {trend && (
              <p className="text-xs text-slate-500 mt-1">*Compared to last Sunday</p>
            )}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  };
  

export default InfoTooltip;
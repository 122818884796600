import { Button, Card, Radio, Select, Space, message } from "antd";
import { OptionComponent, QuestionComponent } from "./McqEditor";
import { IMCQQuestion } from "./types";
import { useEffect } from "react";
import { ClassData } from "../TopicSelector";
import { QuestionStatus } from "../../../features/questions/types";

export interface McqViewerProps {
  classes?: ClassData[];
  question: IMCQQuestion;
  onEdit?: () => void;
  setSavedisabled?: React.Dispatch<React.SetStateAction<boolean>>;
  isSelect?: boolean;
  statement?: string;
  setQuestions?: React.Dispatch<React.SetStateAction<IMCQQuestion[]>>;
  status?: QuestionStatus;
  onStatusChange?: (questionId: string, status: QuestionStatus) => void;
  IsMultipleSubtopics?: boolean;
}

export default function McqViewer({
  question,
  onEdit,
  setSavedisabled,
  isSelect,
  statement,
  setQuestions,
  classes,
  status = QuestionStatus.PENDING,
  onStatusChange,
  IsMultipleSubtopics,
}: McqViewerProps) {
  useEffect(() => {
    if (setQuestions && classes && !IsMultipleSubtopics) {
      setQuestions((prevQuestions: IMCQQuestion[]) =>
        prevQuestions.map((question) => {
          const allConceptTags = findConceptsForQuestion(question, classes);
          return {
            ...question,
            allConceptTags: allConceptTags,
            concept: question?.conceptTags?.map((item: any) => item?.name),
          };
        })
      );
    }
  }, [setQuestions, classes]);
  useEffect(() => {
    if (!IsMultipleSubtopics) {
      let isMatching = true;

      question.concept?.forEach((tag) => {
        const matchedConcept = question.allConceptTags?.find(
          (concept) => concept.name === tag
        );
        if (!matchedConcept?.id) {
          isMatching = false;
        }
      });

      if (!isMatching) {
        setSavedisabled?.(true);
        // message.error("Concepts are not matching.");
      } else {
        setSavedisabled?.(false);
      }
    }
  }, [question, setSavedisabled]);

  function findConceptsForQuestion(
    question: IMCQQuestion,
    classes: ClassData[]
  ) {
    for (const classData of classes) {
      for (const topic of classData.topics) {
        if (topic.id === question.topic) {
          for (const subtopic of topic.subtopics) {
            if (subtopic.id === question.subtopic) {
              return subtopic.concepts;
            }
          }
        }
      }
    }
    return []; // Return empty array if no match found
  }

  // Helper function to decode URL-encoded string and render HTML if necessary
  const renderStatement = () => {
    if (!statement) return null;

    // Check for HTML image tag
    if (statement.includes("<img")) {
      const styledStatement = statement.replace(
        /<img/g,
        '<img style="max-width: 100%; height: auto; max-height: 300px;"'
      );
      return <div dangerouslySetInnerHTML={{ __html: styledStatement }} />;
    }

    // Decode URL-encoded text
    // const decodedText = decodeURIComponent(statement);
    let decodedText;
    try {
      // Decode URL-encoded text
      decodedText = decodeURIComponent(statement);
    } catch (e) {
      // Handle the URIError
      message.error("Error decoding statement");
      console.error("URIError: ", e);
      decodedText = "Error decoding statement"; // or you can set it to a default value or an empty string
    }
    return (
      <p>
        <span className="text-sm font-semibold">Statement:</span> {decodedText}
      </p>
    );
  };

  return (
    <Card
      extra={
        <Space>
          {onEdit && (
            <>
              <Select
                style={{ width: 120 }}
                value={question?.status || QuestionStatus.PENDING}
                onChange={(status) =>
                  onStatusChange?.(
                    question?.id as string,
                    status as QuestionStatus
                  )
                }
              >
                {Object.values(QuestionStatus).map((status) => (
                  <Select.Option key={status} value={status}>
                    {status?.charAt(0).toUpperCase() + status?.slice(1)}
                  </Select.Option>
                ))}
              </Select>
              <Button onClick={onEdit} type="primary">
                Edit
              </Button>
            </>
          )}
        </Space>
      }
      title="Question"
      style={{ marginBottom: "20px" }}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
      }}
    >
      {renderStatement()}
      <span className="">questionId : {question?.id}</span>
      {question.en_question.map((component, idx) => (
        <QuestionComponent {...component} key={idx} />
      ))}
      <Radio.Group
        style={{ width: "100%" }}
        value={question.en_options.find((option) => option.isCorrect)?.text}
      >
        <Space direction="vertical">
          {question.en_options.map((option, idx) => {
            return <OptionComponent option={option} key={idx} />;
          })}
        </Space>
      </Radio.Group>
      <div>
        {onEdit && (
          <div>
            Concepts:{" "}
            {question.concept?.map((tag) =>
              IsMultipleSubtopics ? (
                <span
                  key={tag}
                  style={{
                    marginRight: "5px",
                    color: "green",
                  }}
                >
                  {tag},
                </span>
              ) : (
                <span
                  key={tag}
                  style={{
                    marginRight: "5px",
                    color: question.allConceptTags?.some(
                      (concept) => concept.name === tag
                    )
                      ? "green"
                      : "red",
                  }}
                >
                  {tag},
                </span>
              )
            )}
          </div>
        )}
      </div>
    </Card>
  );
}

import React from "react";
import { useParams } from "react-router-dom";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import Islands from "../islands";
import StudentProfile from "../StudentProfile";

const LearnerProfile = () => {
  const { studentId, schoolId } = useParams();

  return (
    <div className=" flex flex-col items-center  min-h-screen w-full p-6">
      <Tabs
        defaultValue="islands"
        className="w-full max-w-[1400px] flex flex-col items-center "
      >
        <TabsList className="flex justify-center space-x-8 h-[70px] w-[350px] ">
          <TabsTrigger
            value="islands"
            className="text-lg py-3 px-6 font-semibold text-gray-600 data-[state=active]:text-blue-600 data-[state=active]:border-b-4 data-[state=active]:border-blue-600"
          >
            Islands
          </TabsTrigger>
          <TabsTrigger
            value="profile"
            className="text-lg py-3 px-6 font-semibold text-gray-600 data-[state=active]:text-blue-600 data-[state=active]:border-b-4 data-[state=active]:border-blue-600"
          >
            Learner Profile
          </TabsTrigger>
        </TabsList>
        <TabsContent value="islands" className=" text-center w-full">
          <Islands studentId={studentId} schoolId={schoolId} />
        </TabsContent>
        <TabsContent value="profile" className=" w-full">
          <StudentProfile schoolId={schoolId} studentId={studentId} />
        </TabsContent>
      </Tabs>
    </div>
  );
};

export default LearnerProfile;

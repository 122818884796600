import React, { useEffect, useState } from "react";
import {
  Button as AntButton,
  Upload,
  message,
  Spin,
  Drawer,
  Input,
  Card,
  Space,
  Select,
} from "antd";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import {
  IMCQQuestion,
  OriginalQuestionFormat,
} from "../../components/dash-components/Mcq/types";

import McqEditor from "../../components/dash-components/Mcq/McqEditor";
import McqViewer from "../../components/dash-components/Mcq/McqViewer";
import { useUploadImageMutation } from "../../app/api/apiSlice";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import { set, uniqueId } from "lodash";
import { parseIncomingMCQData, validateMCQData, validateTransformedData } from "../../quiz/parseIncomingMCQData";
import { useUploadMcqQuestionsMutation } from "../../quiz/CompetitionApiSlice";
import { toast } from "sonner";
import { useGetQuestionsCatalogueQuery } from "../questions/QuestionsApiSlice";
import TopicSelector from "../../components/dash-components/TopicSelector";
import { Button } from "../../ui/button";
import McqManual from "./McqManual";
import { useGetTopicwiseMcqCountQuery } from "./McqApiSlice";
import StatementCountTable from "../cases/components/StatementsCountTable";
import { CurriculumBoard } from "../EditTopics/EditTopicsApiSlice";
import { useThemeColors } from "../../app/theme/useThemeColors";
import QuestionTemplateDownload from "./SampleMcqDownload";
const { Option } = Select;

interface MCQReviewerProps {
  uploadFile?: (file: any) => Promise<string>; // Function to handle file upload
}

const MCQReviewer: React.FC<MCQReviewerProps> = ({ uploadFile }) => {
  const [questions, setQuestions] = useState<IMCQQuestion[]>([]);
  const [ImcQuestions, setImcQuestions] = useState<IMCQQuestion[]>([]);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editingQuestion, setEditingQuestion] = useState<
    IMCQQuestion | undefined
  >();
  const [loading, setLoading] = useState<boolean>(false);
  const [conceptsMatched, setConceptsMatched] = useState<boolean>();
  const [savedisabled, setSavedisabled] = useState<boolean>(true);
  const [preselectedClass, setPreselectedClass] = useState<string | null>(null);
  const [preselectedTopic, setPreselectedTopic] = useState<string | null>(null);
  const [isManual, setIsManual] = useState<boolean>(false);
  const [preselectedSubtopic, setPreselectedSubtopic] = useState<string | null>(
    null
  );
  const [curriculumBoard, setCurriculumBoard] = useState<CurriculumBoard | "">(
    ""
  );
  const [count, setCount] = useState<number>(0);
  const [category, setCategory] = useState<string | null>(null);
  const [subCategory, setSubCategory] = useState<string | null>(null);
  const {
    data: statementsBySubtopic,
    error,
    isLoading,
  } = useGetTopicwiseMcqCountQuery();

  useEffect(() => {
    if (statementsBySubtopic) {
      setCount(
        statementsBySubtopic.reduce((acc, curr) => {
          return acc + curr.count;
        }, 0)
      );
    }
  }, [statementsBySubtopic]);

  const [uploadAsset] = useUploadImageMutation();
  const [uploadQuestions, isSuccess] = useUploadMcqQuestionsMutation();

  const {
    data: classData,
    isSuccess: isClassDataSuccess,
    isFetching: isClassDataFetching,
  } = useGetQuestionsCatalogueQuery({
    curriculumBoard: curriculumBoard as CurriculumBoard,
  });

  const categories = ["academic", "life-skill", "puzzle"];

  const colors = useThemeColors();

  const subCategories = [
    "LogicPuzzles",
    "MentalMath",
    "VocabularyAndWordRelationships",
    "GeneralKnowledgeTrivia",
    "ScienceFacts",
    "CriticalThinkingScenarios",
    "HistoricalEventsAndTimelines",
    "GeographyQuizzes",
    "Analogies",
    "ProbabilityQuestions",
    "ThisOrThatComparisons",
    "RiddlesAndBrainTeasers",
  ];

  const handleFileUpload = (info: UploadChangeParam<UploadFile<any>>) => {
    const { status } = info.file;
  
    if (status === "done") {
      const reader = new FileReader();
  
      reader.onload = async (e: ProgressEvent<FileReader>) => {
        if (e.target && typeof e.target.result === "string") {
          try {
            const parsedResult = JSON.parse(
              e.target.result
            ) as OriginalQuestionFormat;
  
            // Validate the parsed data
            const validationErrors = validateMCQData(parsedResult);
  
            if (validationErrors.length > 0) {
              // Display all validation errors
              validationErrors.forEach(error => {
                message.error(`${error.field}: ${error.message}`);
              });
              return; // Don't proceed with invalid data
            }
  
            // Proceed with transformation if data is valid
            const transformedData: IMCQQuestion[] = parseIncomingMCQData(parsedResult);
  
            // Ensure each question has a unique key
            transformedData.forEach((q) => {
              q.id = uniqueId();
            });
  
            // Additional validation for transformed data
            const transformedDataErrors = validateTransformedData(transformedData);
            if (transformedDataErrors.length > 0) {
              transformedDataErrors.forEach(error => {
                message.error(`${error.field}: ${error.message}`);
              });
              return;
            }
  
            console.log("transformedData", transformedData);
            setQuestions(transformedData);
            setCurriculumBoard(parsedResult.curriculumBoard as CurriculumBoard);
          } catch (error) {
            message.error("Failed to parse the uploaded file. Please ensure it's a valid JSON file.");
          }
        }
      };
  
      if (info.file.originFileObj) {
        reader.readAsText(info.file.originFileObj);
      }
    } else if (status === "error") {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  const handleEdit = (question: IMCQQuestion) => {
    // console.log("wuooo", question);
    setEditingQuestion(question);
    setIsEditing(true);
  };

  const handleChange = (value: CurriculumBoard | "") => {
    setCurriculumBoard(value);
  };

  const handleSave = (updatedQuestion: IMCQQuestion) => {
    // Update the questions state with the new question data
    setQuestions((prev) =>
      prev.map((q) => (q.id === updatedQuestion.id ? updatedQuestion : q))
    );
    // console.log("updated Question", updatedQuestion);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
  };

  const updateFilter = (selection: any) => {
    const concepts = selection.concept || null;
    const topicId = selection.topicId || null;
    const subtopicId = selection.subtopicId || null;

    setQuestions((prevQuestions: IMCQQuestion[]) =>
      prevQuestions.map((question) => {
        const matchedTags = question.concept
          ?.map((conceptName) => {
            return concepts.find(
              (concept: any) => concept.name === conceptName
            );
          })
          .filter((tag: any) => tag !== undefined);

        let matchedIDs = matchedTags?.map((tag: any) => tag?.id);

        let quest = {
          ...question, // Keep everything else the same
          topic: topicId, // Update the topic field
          subtopic: subtopicId, // Update the subtopic field
          allConceptTags: concepts,
          conceptTags: matchedIDs,
        };
        return quest;
      })
    );
    if (subtopicId || category === "puzzle") {
      setSavedisabled(false);
    } else {
      setSavedisabled(true);
    }
  };

  // Track the previous matching status

  const checkConceptMatching = (question: IMCQQuestion) => {
    const allMatched = question.concept?.every((tag) => {
      const matchedConcept = question.allConceptTags?.find(
        (concept) => concept.name === tag
      );
      return !!matchedConcept?.id;
    });

    // Only trigger toast if the matching status changes
    if (allMatched && conceptsMatched === false) {
      setSavedisabled(false); // Enable the save button
      toast.dismiss(); // Remove any previous error toast
    } else if (!allMatched && conceptsMatched === true) {
      setSavedisabled(true); // Disable the save button
      toast.error("Concepts are not matching");
    }

    // Update the matching status
    setConceptsMatched(allMatched);
  };

  // Check concept matching when questions or filters change
  useEffect(() => {
    questions.forEach(checkConceptMatching);
  }, [questions, updateFilter]);

  useEffect(() => {
    if (isClassDataSuccess && classData && questions.length > 0) {
      const firstQuestion = questions[0];
      const matchedClass = classData.find(
        (c) => c.name === firstQuestion.ClassName
      );
      if (matchedClass) {
        setPreselectedClass(matchedClass.id);
        const matchedTopic = matchedClass.topics.find(
          (t) => t.name === firstQuestion.topicName
        );

        if (matchedTopic) {
          setPreselectedTopic(matchedTopic.id);
          const matchedSubtopic = matchedTopic.subtopics.find(
            (s) => s.name === firstQuestion.subtopicName
          );
          // console.log("anything",matchedSubtopic?.id)
          if (matchedSubtopic) {
            setPreselectedSubtopic(matchedSubtopic.id);
          }
        }
      }

      // console.log(classData)
    }
  }, [isClassDataSuccess, classData, questions]);

  useEffect(() => {
    if (questions.length > 0) {
      const firstQuestion = questions[0];
      if (firstQuestion.category) {
        setCategory(firstQuestion.category);
      }
      if (firstQuestion.subCategory) {
        setSubCategory(firstQuestion.subCategory);
      }
    }
  }, [questions, questions[0]?.category, questions[0]?.subCategory]);

  // console.log("preselected",preselectedClass,preselectedTopic,preselectedSubtopic)
  const onUpload = async () => {
    // Log the questions array before removing the id
    // console.log("Original questions:", questions);

    // Remove the id from each question
    const removedIdQuestion = questions.map(
      ({
        id,
        allConceptTags,
        concept,
        subtopicName,
        topicName,
        ClassName,
        subCategory,
        ...rest
      }) => rest
    );
    let questionsWithCategory = removedIdQuestion;
    if (category && !subCategory) {
      questionsWithCategory = removedIdQuestion.map((q) => ({
        ...q,
        category: category, // Use selected category or empty string
        // Use selected subCategory or empty string
      }));
    } else if (category && subCategory) {
      questionsWithCategory = removedIdQuestion.map((q) => ({
        ...q,
        category: category, // Use selected category or empty string
        subCategory: subCategory, // Use selected subCategory or empty string
      }));
    }

    const uploadedQuestions = await uploadQuestions(questionsWithCategory);
    if (uploadedQuestions.error) {
      toast.error("error in uploading questions");
    } else {
      setPreselectedClass(null);
      setPreselectedTopic(null);
      setPreselectedSubtopic(null);
      setQuestions([]);
      setCategory(null);
      setSubCategory(null);
      toast.success("questions uploaded successfully!!");
    }
    // Log the result to verify id removal
    console.log("Questions without id:", removedIdQuestion);

    // Perform the API upload or save operation here using removedIdQuestion
  };

  const uploadAudio = async (formData: FormData) => {
    const result = await uploadAsset({
      formData,
      prefix: "mcq-audio",
    }).unwrap();
    return result.url;
  };

  const uploadImage = async (formData: FormData) => {
    const result = await uploadAsset({
      formData,
      prefix: "mcq-image",
    }).unwrap();
    return result.url;
  };

  if (isManual) {
    return (
      <div className=" p-3">
        <McqManual />
      </div>
    );
  }

  return (
    <div className=" p-3">
      <div className=" flex justify-between">
        <Select
          style={{ width: 200 }}
          value={curriculumBoard}
          onChange={handleChange}
          placeholder="Select Curriculum Board"
          className="mr-2"
        >
          <Select.Option key="all" value="">
            All
          </Select.Option>
          {Object.values(CurriculumBoard).map((board) => (
            <Select.Option key={board} value={board}>
              {board}
            </Select.Option>
          ))}
        </Select>
        {classData &&
        isClassDataSuccess &&
        !isClassDataFetching &&
        questions.length > 0 ? (
          <div className="flex gap-2">
            <TopicSelector
              classes={classData}
              onChange={updateFilter}
              preselectedClass={preselectedClass}
              preselectedTopic={preselectedTopic}
              preselectedSubtopic={preselectedSubtopic}
            />
            <div className="flex gap-2">
              <Select
                placeholder="Select Category"
                onChange={(value) => {
                  setCategory(value);
                  setSubCategory(null);
                }}
                value={category}
                style={{ width: 150 }}
              >
                {categories.map((cat) => (
                  <Option key={cat} value={cat}>
                    {cat}
                  </Option>
                ))}
              </Select>

              <Select
                placeholder="Select Subcategory"
                disabled={category !== "puzzle"}
                onChange={(value) => setSubCategory(value)}
                value={subCategory}
                style={{ width: 200 }}
              >
                {subCategories.map((subCat) => (
                  <Option key={subCat} value={subCat}>
                    {subCat}
                  </Option>
                ))}
              </Select>
            </div>
          </div>
        ) : questions.length > 0 ? (
          <Spin
            size="small"
            className="ml-2"
            indicator={
              <LoadingOutlined
                style={{ fontSize: 24, color: colors.primaryColor }}
                spin
              />
            }
          />
        ) : null}
      </div>
      {loading ? (
        <Spin tip="Uploading and Parsing..." />
      ) : isEditing ? (
        <Drawer
          title="Edit Questions"
          open={isEditing}
          width={"90%"}
          onClose={() => setIsEditing(false)}
          styles={{ header: { paddingBottom: 32 } }}
          destroyOnClose
        >
          <McqEditor
            data={editingQuestion}
            onSave={handleSave}
            uploadAudio={uploadAudio}
            uploadImage={uploadImage}
            // onSelectionSave={onSelectionSave}
          />
        </Drawer>
      ) : (
        <div className=" h-full ">
          {questions.length > 0 ? (
            <div className=" mt-3  h-screen">
              {questions.map((question) => (
                <McqViewer
                  key={question.id}
                  question={question}
                  onEdit={() => handleEdit(question)}
                  setSavedisabled={setSavedisabled}
                />
              ))}

              <Button
                disabled={savedisabled}
                onClick={onUpload}
                className="bg-[#a42fc6] text-white ml-2 mb-4"
              >
                Save
              </Button>
            </div>
          ) : (
            <div className=" flex flex-col w-full   h-full  ">
              <Card
                title={`Multiple Choice questions | ${count}`}
                extra={
                  <Space className="">
                    <AntButton
                      type="dashed"
                      onClick={() => setIsManual(true)}
                      className=" "
                    >
                      Manual Upload
                    </AntButton>
                    <Upload
                      accept=".json"
                      showUploadList={false}
                      customRequest={({ onSuccess }) => {
                        setTimeout(() => {
                          onSuccess?.("ok" as any);
                        }, 0);
                      }}
                      onChange={handleFileUpload}
                    >
                      <AntButton type="primary" icon={<UploadOutlined />}>
                        Upload File
                      </AntButton>
                    </Upload>
                    <QuestionTemplateDownload
                      filename="physics-questions.json"
                      buttonText="Download Sample Questions"
                    />
                  </Space>
                }
              >
                {statementsBySubtopic ? (
                  <StatementCountTable data={statementsBySubtopic || []} />
                ) : (
                  <div className="w-full flex justify-center">
                    <Spin size="large" />
                  </div>
                )}
              </Card>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MCQReviewer;



// const handleFileUpload = (info: UploadChangeParam<UploadFile<any>>) => {
  //   const { status } = info.file;

  //   if (status === "done") {
  //     const reader = new FileReader();

  //     reader.onload = async (e: ProgressEvent<FileReader>) => {
  //       if (e.target && typeof e.target.result === "string") {
  //         try {
  //           const parsedResult = JSON.parse(
  //             e.target.result
  //           ) as OriginalQuestionFormat;

  //           // Use parseIncomingMCQData to transform the parsed data
  //           const transformedData: IMCQQuestion[] =
  //             parseIncomingMCQData(parsedResult);

  //           // Ensure each question has a unique key
  //           transformedData.forEach((q) => {
  //             q.id = uniqueId();
  //           });
  //           // Additional validation for transformed data
  //         const transformedDataErrors = validateTransformedData(transformedData);
  //         if (transformedDataErrors.length > 0) {
  //           transformedDataErrors.forEach(error => {
  //             message.error(`${error.field}: ${error.message}`);
  //           });
  //           return;
  //         }
  //           console.log("transfromedData", transformedData);
  //           // Update the state with the transformed data
  //           setQuestions(transformedData);
  //           setCurriculumBoard(parsedResult.curriculumBoard as CurriculumBoard);
  //         } catch (error) {
  //           message.error("Failed to parse the uploaded file.");
  //         }
  //       }
  //     };

  //     if (info.file.originFileObj) {
  //       reader.readAsText(info.file.originFileObj);
  //     }
  //   } else if (status === "error") {
  //     message.error(`${info.file.name} file upload failed.`);
  //   }
  // };
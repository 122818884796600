export enum AppName {
  Neo = "neo",
  Gyaani = "gyaani",
}
//  const HOST = "http://localhost:3000";


let HOST = "https://api.prod.neoapp.in";


//let HOST = "https://api.dev.bheem.ai";
let ADMIN_PORTAL_URL = "http://dash.neoapp.in";

export { ADMIN_PORTAL_URL, HOST };





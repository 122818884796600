import React, { useEffect, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../ui/select";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";
import {
  AlertCircle,
  Loader2,
  RefreshCcw,
  LineChart as ChartIcon,
  BookX,
} from "lucide-react";
import { Button } from "../../../ui/button";
import InfoTooltip from "./InfoTooltip";
import { useGetStudentMasteryProgressQuery } from "../../schools/SchoolsApiSlice";

const subjects = [
  "All Subjects",
  "Math",
  "Science",
  "English",
  "Social-Science",
];

const MasteryGrowthChart = ({
  studentId,
  schoolId,
}: {
  studentId?: string;
  schoolId?: string;
}) => {
  const {
    data: allData,
    isLoading,
    isError,
  } = useGetStudentMasteryProgressQuery({
    studentId,
    schoolId,
  });
  const [selectedSubject, setSelectedSubject] = useState<string | undefined>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const data = allData?.data;
  useEffect(() => {
    if (allData?.subjectFilters?.length > 0) {
      setSelectedSubject(allData?.subjectFilters?.[0]);
    }
  }, [allData]);

  // Filter data based on selected subject
  // const filteredData =
  //   selectedSubject === "All Subjects"
  //     ? AllSubjectsData
  //     : mockData.filter((item) => item.subject === selectedSubject);
  const filteredData =
    data
      ?.filter(
        (item: any) =>
          selectedSubject === item?.subject ||
          selectedSubject === "All Subjects"
      )
      .flatMap((item: any) => item.data) || [];

  // Empty state component
  const EmptyState = () => (
    <div className="flex flex-col items-center justify-center py-16 px-4">
      <div className="bg-gray-50 rounded-full p-4 mb-4">
        <BookX className="w-12 h-12 text-gray-400" />
      </div>
      <h3 className="text-xl font-semibold text-gray-900 mb-2">
        No data available
      </h3>
      <p className="text-gray-500 text-center max-w-sm mb-6">
        We couldn't find any mastery data for the selected subject. Try
        selecting a different subject or time period.
      </p>
      <Button
        variant="outline"
        className="flex items-center space-x-2"
        onClick={() => setSelectedSubject("All Subjects")}
      >
        <RefreshCcw className="w-4 h-4" />
        <span>View all subjects</span>
      </Button>
    </div>
  );

  // Loading state component
  const LoadingState = () => (
    <div className="flex flex-col items-center justify-center py-16">
      <Loader2 className="w-12 h-12 animate-spin text-primary mb-4" />
      <p className="text-gray-500 font-medium">Loading your mastery data...</p>
    </div>
  );

  // Error state component
  const ErrorState = () => (
    <div className="flex flex-col items-center justify-center py-16 px-4">
      <div className="bg-red-50 rounded-full p-4 mb-4">
        <AlertCircle className="w-12 h-12 text-red-500" />
      </div>
      <h3 className="text-xl font-semibold text-gray-900 mb-2">
        Failed to load data
      </h3>
      <p className="text-gray-500 text-center max-w-sm mb-6">
        We encountered an error while fetching your mastery data. Please try
        again or contact support if the problem persists.
      </p>
      <Button
        variant="default"
        className="flex items-center space-x-2"
        onClick={() => setError(false)}
      >
        <RefreshCcw className="w-4 h-4" />
        <span>Try again</span>
      </Button>
    </div>
  );

  // Demo toggles for different states
  const toggleLoading = () => {
    setLoading(!loading);
    setError(false);
    setIsEmpty(false);
  };

  const toggleError = () => {
    setError(!error);
    setLoading(false);
    setIsEmpty(false);
  };

  const toggleEmpty = () => {
    setIsEmpty(!isEmpty);
    setLoading(false);
    setError(false);
  };
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (!active || !payload) return null;

    return (
      <div className="bg-white border border-slate-200 rounded-lg shadow-sm p-3">
        <p className="text-sm font-medium text-slate-900 mb-2">{label}</p>
        {payload?.map((entry: any, index: any) => (
          <div key={index} className="flex items-center gap-2 mb-1 last:mb-0">
            <div
              className="w-2 h-2 rounded-full"
              style={{ backgroundColor: entry.color }}
            />
            <span className="text-sm text-slate-600">{entry.name}:</span>
            <span className="text-sm font-medium text-slate-900">
              {entry.value}%
            </span>
          </div>
        ))}
      </div>
    );
  };

  return (
    <Card className="w-full border-none  ">
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-7">
        <div className="flex items-center space-x-3">
          <ChartIcon className="w-6 h-6 text-primary" />
          <CardTitle className="text-xl font-bold flex items-center">
            Mastery Growth Comparison
            <InfoTooltip
              description="Monthly mastery trends showing student's progress alongside class average and top performer. View overall growth or filter by specific subjects."
              trend={false}
            />
          </CardTitle>
        </div>
        <div className="flex items-center space-x-4">
          {/* Demo toggle buttons - remove in production */}
          {/* <button
            onClick={toggleLoading}
            className="px-3 py-1 text-xs bg-blue-100 text-blue-600 rounded-full"
          >
            Toggle Loading
          </button>
          <button
            onClick={toggleError}
            className="px-3 py-1 text-xs bg-red-100 text-red-600 rounded-full"
          >
            Toggle Error
          </button>
          <button
            onClick={toggleEmpty}
            className="px-3 py-1 text-xs bg-gray-100 text-gray-600 rounded-full"
          >
            Toggle Empty
          </button> */}
          <Select value={selectedSubject} onValueChange={setSelectedSubject}>
            <SelectTrigger className="w-[180px]">
              <SelectValue placeholder="Select subject" />
            </SelectTrigger>
            <SelectContent>
              {allData?.subjectFilters?.map((subject: any) => (
                <SelectItem key={subject} value={subject}>
                  {subject}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </CardHeader>
      <CardContent>
        {loading ? (
          <LoadingState />
        ) : error ? (
          <ErrorState />
        ) : isEmpty || filteredData?.length === 0 ? (
          <EmptyState />
        ) : (
          <div className="h-96">
            <ResponsiveContainer width="100%" height="100%">
              <LineChart
                data={filteredData}
                margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" className="opacity-50" />
                <XAxis
                  dataKey="month"
                  tick={{ fill: "#6b7280" }}
                  axisLine={{ stroke: "#e5e7eb" }}
                />
                <YAxis
                  domain={[0, 100]}
                  tick={{ fill: "#6b7280" }}
                  axisLine={{ stroke: "#e5e7eb" }}
                />
                <Tooltip content={<CustomTooltip />} />
                <Legend
                  verticalAlign="top"
                  height={36}
                  iconType="circle"
                  iconSize={8}
                />
                <Line
                  type="monotone"
                  dataKey="topperScore"
                  stroke="#16a34a"
                  name="Top Score"
                  strokeWidth={2.5}
                  dot={{ r: 4, strokeWidth: 2 }}
                  activeDot={{ r: 6, strokeWidth: 2 }}
                />
                <Line
                  type="monotone"
                  dataKey="studentScore"
                  stroke="#2563eb"
                  name="Your Score"
                  strokeWidth={2.5}
                  dot={{ r: 4, strokeWidth: 2 }}
                  activeDot={{ r: 6, strokeWidth: 2 }}
                />
                <Line
                  type="monotone"
                  dataKey="classAverage"
                  stroke="#f97316"
                  name="Class Average"
                  strokeWidth={2.5}
                  dot={{ r: 4, strokeWidth: 2 }}
                  activeDot={{ r: 6, strokeWidth: 2 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        )}
      </CardContent>
    </Card>
  );
};

export default MasteryGrowthChart;

export interface SubtopicType {
  _id: string;
  name: string;
  description: string;
  learningStatus: "pending" | "in-progress" | "completed";
  pctComplete: number;
  totalReads: number;
  parent: string;
  type: "subtopic";
  isActive: boolean;
  slug: string;
  subtopicSequence: number;
  isAvailableFree: boolean;
  coverImages?: string[];
  concepts: string[];
  isChapterOpenForTeacherActivity: boolean;
  gradeLevel: number;
  preparatoryChapters: any[];
  subjectGroupIds: any[];
  isPracticeContentAvailable: boolean;
  isRevisionContentAvailable: boolean;
  curriculumBoard: string;
  resumeSessionId: string | null;
}

export interface PathwayData {
  _id: string;
  name: string;
  description: string;
  type: "topic";
  isActive: boolean;
  slug: string;
  parent: string;
  coverImages?: string[];
  concepts: string[];
  gradeLevel: number;
  isAvailableFree: boolean;
  preparatoryChapters: any[];
  subjectGroupIds: any[];
  isPracticeContentAvailable: boolean;
  isRevisionContentAvailable: boolean;
  curriculumBoard: string;
  rating: number;
  subtopics: SubtopicType[];
  meta: {
    userRating?: number;
    isSuspended?: boolean;
    progressPct: number;
    masteryPct: number;
    totalSections: number;
    completedSections: number;
  };
  ctaText?: string;
}

export const SUBJECT_COLORS = [
  "#E86464", // Red
  "#4B99B0", // Teal
  "#5B89D9", // Blue
  "#4BCDB9", // Turquoise
  "#A167F5", // Purple
  "#FF6B9C", // Pink
  "#D4477C", // Magenta
  "#E69040", // Orange
  "#90B640", // Green
  "#C44FD1", // Violet
  "#EBD68C", // Gold
  "#B47A54", // Brown
  "#50C878", // Emerald
  "#9370DB", // Medium Purple
  "#20B2AA", // Light Sea Green
  "#FF69B4", // Hot Pink
  "#FFD700", // Golden Yellow
  "#8A2BE2", // Blue Violet
  "#00CED1", // Dark Turquoise
  "#FF7F50", // Coral
  "#7B68EE", // Medium Slate Blue
  "#32CD32", // Lime Green
  "#FF4500", // Orange Red
  "#9932CC", // Dark Orchid
] as const;

export interface MotionConfig {
  initial?: {
    opacity?: number;
    y?: number;
    scale?: number;
  };
  animate?: {
    opacity?: number;
    y?: number;
    scale?: number;
  };
  exit?: {
    opacity?: number;
    scale?: number;
  };
  transition?: {
    delay?: number;
    duration?: number;
  };
}

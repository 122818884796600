import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../ui/accordion";
import GradeReport from "./GradeReport";
import InfoTooltip from "./InfoTooltip";

interface ICourseData {
  courseName: string;
  elm: number; // Essential learning mastery
  hots: number; // Higher Order Thinking Skill
  mastery: number;
  comprehensionMastery: number;
  memoryMastery: number;
  weakConcepts: {
    chapterName: string;
    conceptName: string;
    elm: number;
    hots: number;
    mastery: number;
    comprehensionMastery: number;
    memoryMastery: number;
  }[];
}

interface IWeakConceptData {
  chapterName: string;
  conceptName: string;
  elm: number; // Essential learning mastery
  hots: number; // Higher Order Thinking Skill
  mastery: number;
}

type IGradeData = ICourseData[];

type IPriorLearningIndex = {
  grade: number;
  mastery: number;
  data: IGradeData;
}[];

interface PriorLearningIndexProps {
  priorLearningData: IPriorLearningIndex;
}

function PriorLearningIndex({ priorLearningData }: PriorLearningIndexProps) {
  const sortedData = [...priorLearningData].sort((a, b) => b?.grade - a?.grade);
  return (
    <div className="flex flex-col gap-4">
      <h4 className="text-2xl font-bold text-slate-800 tracking-tight pb-4">
        Grade-Wise Competency
        <InfoTooltip
          description="Student's mastery levels in previous grade concepts, organized by subject, showing strengths and gaps in foundational knowledge."
          trend={false}
        />
      </h4>

      <Accordion
        type="single"
        collapsible
        className="w-full divide-y bg-white rounded-lg  border-slate-200"
      >
        {sortedData?.map((grade, idx) => (
          <AccordionItem
            key={idx}
            value={`item-${idx}`}
            className="border-none"
          >
            <AccordionTrigger className="hover:no-underline py-4 transition-colors hover:bg-slate-50 rounded-lg px-4">
              <div className="flex items-center justify-between w-full">
                <div className="flex items-center gap-4">
                  <span className="font-semibold text-lg text-slate-900">
                    Grade {grade?.grade}
                  </span>
                  <div className="flex items-center gap-2 bg-slate-100 px-3 py-1 rounded-full">
                    <div
                      className={`w-2 h-2 rounded-full ${
                        grade.mastery >= 70 ? "bg-emerald-500" : "bg-amber-500"
                      }`}
                    />
                    <span className="text-sm font-medium text-slate-600">
                      {grade?.mastery}% Mastery
                    </span>
                  </div>
                </div>
              </div>
            </AccordionTrigger>
            <AccordionContent>
              <div className="pt-6 px-4">
                <GradeReport data={grade?.data} />
              </div>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
}

export default PriorLearningIndex;

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { HOST } from "../constants";
// import { clearUser } from "@/auth/authSlice";

const BASE_API_URL = `${HOST}/api/v3`;

// const baseQuery = fetchBaseQuery({
//   baseUrl: BASE_API_URL,
//   credentials: "include",
//   prepareHeaders: (headers, { getState }) => {
//     headers.set("x-app-name", "NeoAdmin");
//     return headers;
//   },
// });

const baseQuery = fetchBaseQuery({
  baseUrl: BASE_API_URL,
  credentials: "include",
  prepareHeaders: (headers, { getState }) => {
    const token = (getState() ).auth.token; // Get token from Redux store
    if (token) {
      headers.set("authorization", `Bearer ${token}`); // Add authorization header
    }
    headers.set("x-app-name", "NeoAdmin");
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  // eslint-disable-line @typescript-eslint/no-explicit-any
  // const dispatch = api.dispatch;
  let result = await baseQuery(args, api, extraOptions);
  if (result.error?.status === 401) {
    // dispatch(clearUser());
    window.location.href = "/";
  }
  return result;
};

const apiSliceI = createApi({
  reducerPath: "apiSliceI",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
   
    uploadImage: builder.mutation({
      query: ({ formData, prefix }) => ({
        url: `/applications/neo/upload-image?prefix=${prefix}`,
        method: "POST",
        body: formData,
      }),
    }),
  }),
});

const { useUploadImageMutation  } = apiSliceI;

export { useUploadImageMutation, apiSliceI };

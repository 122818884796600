import { useState } from "react";
import { SubjectTile } from "./SubjectTile";
import { SubjectDetails } from "./SubjectDetails";
import type { PathwayData } from "./types";
import { SUBJECT_COLORS } from "./types";
import { useGetPathwaysQuery } from "../schools/SchoolsApiSlice";


export default function Islands({studentId, schoolId}:{studentId?:string, schoolId?:string}) {
  const [selectedPathway, setSelectedPathway] = useState<PathwayData | null>(
    null
  );
  const [selectedSubtopicId, setSelectedSubtopicId] = useState<string | null>(
    null
  );
  const { data: apiData, isLoading } = useGetPathwaysQuery(studentId);

  if (isLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <div className="animate-spin h-8 w-8 border-4 border-primary border-t-transparent rounded-full" />
      </div>
    );
  }

  const pathways = apiData?.pathways || [];
  return (
    <div className="min-h-screen bg-gray-900 p-12">
      <div className="max-w-7xl mx-auto">
        <div className="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-8 items-center justify-items-center">
          {pathways.map((pathway: PathwayData, idx: number) => (
            <SubjectTile
              key={pathway._id}
              data={pathway}
              color={SUBJECT_COLORS[idx % SUBJECT_COLORS.length]}
              onSelect={(pathway, subtopicId) => {
                setSelectedPathway(pathway);
                setSelectedSubtopicId(subtopicId);
              }}
            />
          ))}
        </div>
      </div>

      <SubjectDetails
        selectedPathway={selectedPathway}
        selectedSubtopicId={selectedSubtopicId}
        isRevisionContentAvailable={
          selectedPathway?.isRevisionContentAvailable || false
        }
        onClose={() => {
          setSelectedPathway(null);
          setSelectedSubtopicId(null);
        }}
        color={
          SUBJECT_COLORS[
            pathways.findIndex(
              (p: PathwayData) => p._id === selectedPathway?._id
            ) % SUBJECT_COLORS.length
          ]
        }
      />
    </div>
  );
}

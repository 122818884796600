import { Card } from "../../../ui/card";
import { useState } from "react";

import { GradeTable } from "./GradeTable";
import { WeakConceptsDialog } from "./WeakConceptsDialog";
import { GradeData, WeakConceptData } from "./GradeProgress";

type GradeReportProps = {
  data: GradeData[];
};

function GradeReport({ data }: GradeReportProps) {
  const [weakConcepts, setWeakConcepts] = useState<WeakConceptData[]>([]);

  return (
    <>
      <Card className="min-h-[300px]">
        <GradeTable data={data} onWeakConceptsClick={setWeakConcepts} />
      </Card>

      <WeakConceptsDialog
        weakConcepts={weakConcepts}
        onClose={() => setWeakConcepts([])}
      />
    </>
  );
}

export default GradeReport;

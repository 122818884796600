import { Progress } from "antd";

const ProgressBar = ({ d }: { d: number }) => {
  let color;
  if (d > 85) {
    color = "#22c55e";
  } else if (d > 75) {
    color = "#3b82f6";
  } else {
    color = "#f43f5e";
  }

  return <Progress steps={5} percent={d} strokeColor={color} />;
};

export default ProgressBar;

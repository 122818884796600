import {
  IMCQQuestion,
  MCQOption,
  MCQuestionVersion,
  OptionType,
  OriginalProfilingQuestionFormat,
  OriginalQuestionFormat,
  QuestionComponentType,
  TextComponent,
} from "../../components/dash-components/Mcq/types";

interface ValidationError {
  field: string;
  message: string;
}

export const validateProfilingData = (
  data: OriginalProfilingQuestionFormat
): ValidationError[] => {
  const errors: ValidationError[] = [];

  const hasCategoryPair = data.category && data.subCategory;
  const hasSubjectPath = data.subject && data.Grade;

  if (!hasCategoryPair && !hasSubjectPath) {
    errors.push({
      field: "requiredFields",
      message:
        "Either (category and subCategory) OR (subject, chapter, and Grade) must be provided",
    });
  }

  // Curriculum Board is always required
  if (!data.curriculumBoard) {
    errors.push({
      field: "curriculumBoard",
      message: "Curriculum Board is required",
    });
  }

  // If subject/chapter path is used, validate all required fields
  if (!hasCategoryPair) {
    if (!data.subject) {
      errors.push({
        field: "subject",
        message: "Subject is required when category is not provided",
      });
    }
    if (!data.Grade) {
      errors.push({
        field: "Grade",
        message: "Grade is required when category is not provided",
      });
    }
  }

  // Validate questions array
  if (!Array.isArray(data.questions) || data.questions.length === 0) {
    errors.push({
      field: "questions",
      message: "At least one question is required",
    });
    return errors;
  }

  // Validate each question
  data.questions.forEach((q, index) => {
    if (!q.question || q.question.trim() === "") {
      errors.push({
        field: `questions[${index}].question`,
        message: `Question text is required for question ${index + 1}`,
      });
    }

    if (!Array.isArray(q.options) || q.options.length !== 4) {
      errors.push({
        field: `questions[${index}].options`,
        message: `Exactly 4 options are required for question ${index + 1}`,
      });
    }

    if (!q.answer || !q.options.includes(q.answer)) {
      errors.push({
        field: `questions[${index}].answer`,
        message: `Valid answer must be one of the options for question ${
          index + 1
        }`,
      });
    }

    if (q.difficultylevel && isNaN(parseFloat(q.difficultylevel))) {
      errors.push({
        field: `questions[${index}].difficultylevel`,
        message: `Difficulty level must be a valid number for question ${
          index + 1
        }`,
      });
    }

    if (q.year && isNaN(parseInt(q.year, 10))) {
      errors.push({
        field: `questions[${index}].year`,
        message: `Year must be a valid number for question ${index + 1}`,
      });
    }
  });

  return errors;
};

export const validateProfilingTransformedData = (
  data: IMCQQuestion[]
): ValidationError[] => {
  const errors: ValidationError[] = [];

  data.forEach((q, index) => {
    if (!q.en_question || q.en_question.length === 0) {
      errors.push({
        field: `Question ${index + 1}`,
        message: "English question is required",
      });
    }

    if (!q.en_options || q.en_options.length !== 4) {
      errors.push({
        field: `Question ${index + 1}`,
        message: "Four English options are required",
      });
    }

    // Check required fields based on category presence
    const hasCategoryPair = q.category && q.subCategory;
    const hasSubjectPath = q.topicName  && q.ClassName;

    if (!hasCategoryPair && !hasSubjectPath) {
      errors.push({
        field: `Question ${index + 1}`,
        message:
          "Either (category and subCategory) OR (subject, chapter, and Grade) must be present",
      });
    }

    // Check if at least one option is marked as correct
    const hasCorrectOption = q.en_options?.some((option) => option.isCorrect);
    if (!hasCorrectOption) {
      errors.push({
        field: `Question ${index + 1}`,
        message: "At least one correct option must be marked",
      });
    }
  });

  return errors;
};

export const parseProfilingIncomingData = (
  data: OriginalProfilingQuestionFormat
): IMCQQuestion[] => {
  return data.questions.map((q) => {
    // Transform the question into the required format
    const en_question: TextComponent[] = [
      {
        type: QuestionComponentType.TEXT,
        value: q.question,
      },
    ];

    const concepts = q.conceptTags;
    const chapter = q.chapter;
    // Transform the options
    const en_options: MCQOption[] = q.options.map((option) => ({
      type: OptionType.TEXT,
      text: option,
      isCorrect: option === q.answer, // Mark the correct option
    }));
    const SubCategory = data.subCategory
      ? data.subCategory.replace(/\s+/g, "") // Remove spaces
      : "";

    // Return the transformed question object in the required format
    return {
      en_question,
      en_options,
      hi_question: null, // Assuming no Hindi question
      hi_options: null, // Assuming no Hindi options
      topic: "", // Replace with dynamic or constant topic ID
      subtopic: "", // Replace with dynamic or constant subtopic ID
      type: data.type || "mcq", // mcq in this case
      formatType: "FOUR_OPTIONS", // Assuming all are four-option MCQs
      tags: [],
      conceptTags: [],
      allConceptTags: [],
      concept: concepts || [], // Taking conceptTags from incoming data
      preRequisiteConcepts: [],
      bloomLevel: data.bloomLevel,
      level: null,
      meta: [
        {
          type: "hint",
          text: q.hint || "example hint will change later",
        },
      ],
      explaination: q.explanation,
      isCompetitionQuestion: null,
      status: "pending", // Assuming all questions start with "pending" status
      problemStatement: null,
      isActive: true,
      questionHash: "false", // Modify this as needed
      ClassName: `Class - ${data.Grade}`,
    //   subtopicName: chapter,
      multipleSubtopicsName: chapter,
      topicName: data.subject,
      category: data.category === "Puzzle Rush" ? "puzzle" : data.category,
      subCategory: SubCategory,
      difficultyLevel: q.difficultylevel ? parseFloat(q.difficultylevel) : 0, // Refactored handling of difficultyLevel
      source: data?.Source,
      author: data?.Author,
      year: q?.year ? parseInt(q.year, 10) : undefined,
      curriculumBoard: data?.curriculumBoard || "",
      version: data?.version || MCQuestionVersion.V2,
      isProfiling: data?.isProfiling,
    };
  });
};

// Example usage of the parse function
// const incomingData: OriginalQuestionFormat = {
//   Grade: "6",
//   subject: "Maths 2024",
//   chapter: "Perimeter and Area",
//   conceptTags: ["Perimeter"],
//   type: "mcq",
//   Source: "Neo Original",
//   bloomLevel: 1,
//   questions: [
//     {
//       question:
//         "What is the perimeter of a square with a side length of 5 meters?",
//       options: ["10 meters", "20 meters", "25 meters", "30 meters"],
//       answer: "20 meters",
//       explanation:
//         "The perimeter of a square is calculated as $4 \\times \\text{side}$. So, $4 \\times 5 = 20$ meters.",
//       hint: "Think about the formula for the perimeter of a square.",
//     },
//     {
//       question:
//         "If a triangle has sides of 3 cm, 4 cm, and 5 cm, what is its perimeter?",
//       options: ["10 cm", "12 cm", "14 cm", "15 cm"],
//       answer: "12 cm",
//       explanation:
//         "The perimeter of a triangle is the sum of the lengths of all its sides: $3 + 4 + 5 = 12$ cm.",
//       hint: "Add the lengths of the three sides of the triangle.",
//     },
//   ],
// };

// // Parsing incoming data
// const parsedData: IMCQQuestion[] = parseIncomingMCQData(incomingData);
// console.log(parsedData);

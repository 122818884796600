import { Button } from "../../../ui/button";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table";
import { toast } from "sonner";

import ProgressBar from "./ProgressIndicator";
import {
  Brain,
  BookOpen,
  Lightbulb,
  Trophy,
  AlertCircle,
  Bot,
  CheckCircle2,
} from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../ui/tooltip";
import { GradeData, WeakConceptData } from "./GradeProgress";

interface GradeTableProps {
  data: GradeData[];
  onWeakConceptsClick: (concepts: WeakConceptData[]) => void;
}

export const GradeTable = ({ data, onWeakConceptsClick }: GradeTableProps) => {
  return (
    <Table>
      <TableHeader>
        <TableRow className="bg-slate-50">
          <TableHead className="font-semibold">Subject</TableHead>
          <TableHead colSpan={3}>
            <div className="grid grid-cols-3 gap-4 px-4">
              <div className="flex items-center justify-center">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger className="flex items-center gap-2">
                      <Brain className="h-4 w-4" />
                      Memory
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Memory retention score</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
              <div className="flex items-center justify-center">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger className="flex items-center gap-2">
                      <BookOpen className="h-4 w-4" />
                      Comprehension
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Understanding of concepts</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
              <div className="flex items-center justify-center">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger className="flex items-center gap-2">
                      <Lightbulb className="h-4 w-4" />
                      <span>HOTS</span>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Higher Order Thinking Skills</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </div>
          </TableHead>
          <TableHead className="text-center">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger className="flex items-center justify-center gap-2">
                  <Trophy className="h-4 w-4" />
                  Overall Mastery
                </TooltipTrigger>
                <TooltipContent>
                  <p>Combined performance score</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </TableHead>
          <TableHead colSpan={2} className="text-center">
            <div className="flex justify-center">
              Concepts Overview
            </div>
          </TableHead>
          <TableHead className="text-right">AI Intervention</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((row, i) => (
          <TableRow key={i} className="hover:bg-slate-50/50">
            <TableCell className="font-medium">{row.courseName}</TableCell>
            <TableCell colSpan={3}>
              <div className="grid grid-cols-3 gap-4 px-4">
                <div className="flex justify-center">
                  <ProgressBar d={row.memoryMastery} />
                </div>
                <div className="flex justify-center">
                  <ProgressBar d={row.comprehensionMastery} />
                </div>
                <div className="flex justify-center">
                  <ProgressBar d={row.hots} />
                </div>
              </div>
            </TableCell>
            <TableCell>
              <div className="flex justify-center">
                <ProgressBar d={row.mastery} />
              </div>
            </TableCell>
            <TableCell colSpan={2}>
              <div className="flex items-center justify-center px-4 flex-wrap gap-2">
                <div>
                  {row.weakConcepts.length === 0 ? (
                    <span className="inline-flex items-center text-emerald-700 gap-1">
                      <CheckCircle2 className="h-4 w-4" /> No weak concepts
                    </span>
                  ) : (
                    <Button
                      variant="outline"
                      className="text-red-500 font-semibold hover:bg-red-50"
                      onClick={() => onWeakConceptsClick(row.weakConcepts)}
                    >
                      <AlertCircle className="h-4 w-4 mr-1" />
                      {row.weakConcepts.length}
                    </Button>
                  )}
                </div>
                {/* <span className="font-medium text-slate-600 ml-2">
                  11/20 assessed
                </span> */}
              </div>
            </TableCell>
            <TableCell className="text-right">
              {row.weakConcepts.length > 0 ? (
                <div
                  className="inline-flex items-center gap-1 text-blue-400 hover:text-blue-700 cursor-pointer"
                  onClick={() => toast.info("Available in Premium subscription")}
                >
                  <Bot className="h-4 w-4" />
                  Coming Soon
                </div>
              ) : (
                <span className="text-gray-500 inline-flex items-center gap-1">
                  <CheckCircle2 className="h-4 w-4" />
                  Not required
                </span>
              )}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
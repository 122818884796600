import React, { useState, useEffect } from "react";
import { Select, Space } from "antd";
import { SelectValue } from "antd/es/select";

interface Concept {
  id: string;
  name: string;
}

interface Subtopic {
  id: string;
  name: string;
  concepts: Concept[];
}

interface Topic {
  id: string;
  name: string;
  subtopics: Subtopic[];
}

export interface ClassData {
  id: string;
  name: string;
  topics: Topic[];
}

interface MultiLevelSelectorProps {
  classes: ClassData[];
  onChange: (selection: {
    classId: string | null;
    topicId: string | null;
    subtopicId: string | null;
    concept: Concept[];
    grade?: string;
  }) => void;
  preselectedClass?: string | null;
  preselectedTopic?: string | null;
  preselectedSubtopic?: string | null;
  activeCaseIndex?: number;
}

const MultiLevelSelector: React.FC<MultiLevelSelectorProps> = ({
  classes,
  onChange,
  preselectedClass = null,
  preselectedTopic = null,
  preselectedSubtopic = null,
  activeCaseIndex,
}) => {
  const [selectedClass, setSelectedClass] = useState<string | null>(preselectedClass);
  const [selectedTopic, setSelectedTopic] = useState<string | null>(preselectedTopic);
  const [selectedSubtopic, setSelectedSubtopic] = useState<string | null>(preselectedSubtopic);
  const [selectedConcepts, setSelectedConcepts] = useState<Concept[]>([]);
  const [selectedGrade, setSelectedGrade] = useState<string >();

  const selectedClassObj = classes.find((c) => c.id === selectedClass);
  const selectedTopicObj = selectedClassObj?.topics.find((t) => t.id === selectedTopic);
  const selectedSubtopicObj = selectedTopicObj?.subtopics.find((s) => s.id === selectedSubtopic);

  const handlePresetValues = () => {
    const matchedClass = classes.find((cls) => cls.id === preselectedClass);
    if (matchedClass) {
      const matchedTopic = matchedClass.topics.find(
        (topic) => topic.id === preselectedTopic
      );
      if (matchedTopic) {
        const matchedSubtopic = matchedTopic.subtopics.find(
          (subtopic) => subtopic.id === preselectedSubtopic
        );
        const matchedConcepts = matchedSubtopic?.concepts || [];

        // Set all the preset values at once
        setSelectedClass(matchedClass.id);
        setSelectedTopic(matchedTopic.id);
        setSelectedSubtopic(matchedSubtopic?.id || null);
        setSelectedConcepts(matchedConcepts);
      }
    }
  };

  // Use this effect to set the initial preset values only once
  useEffect(() => {
    if (preselectedClass || preselectedTopic || preselectedSubtopic) {
      handlePresetValues();
    }
  }, [preselectedClass, preselectedTopic, preselectedSubtopic]);

  useEffect(() => {
    if (selectedSubtopicObj) {
      // Automatically set all concept IDs from the selected subtopic
      const subtopicConceptIds = selectedSubtopicObj.concepts;
      setSelectedConcepts(subtopicConceptIds);
    } else {
      setSelectedConcepts([]);
    }
  }, [selectedSubtopicObj]);
  useEffect(() => {
    onChange({
      classId: selectedClass,
      topicId: selectedTopic,
      subtopicId: selectedSubtopic,
      concept: selectedConcepts,
      grade: selectedGrade,
    });
  }, [selectedClass, selectedTopic, selectedSubtopic, selectedConcepts,activeCaseIndex]);

  const handleClassChange = (value: SelectValue) => {
    const selectedClassObject = classes.find((c) => c.id === value);
    setSelectedGrade(selectedClassObject?.name);
    setSelectedClass(value as string);
    setSelectedTopic(null);
    setSelectedSubtopic(null);
    setSelectedConcepts([]);
  };

  const handleTopicChange = (value: SelectValue) => {
    setSelectedTopic(value as string);
    setSelectedSubtopic(null);
    setSelectedConcepts([]);
  };

  const handleSubtopicChange = (value: SelectValue) => {
    setSelectedSubtopic(value as string);
    setSelectedConcepts([]);
  };

  return (
    <Space size="large">
      {/* Class Selection */}
      <Select
        style={{ width: 200 }}
        placeholder="Select Class"
        value={selectedClass}
        onChange={handleClassChange}
      >
        {classes.map((c) => (
          <Select.Option key={c.id} value={c.id}>
            {c.name}
          </Select.Option>
        ))}
      </Select>

      {/* Topic Selection */}
      <Select
        style={{ width: 200 }}
        placeholder="Select Subject"
        value={selectedTopic}
        onChange={handleTopicChange}
        disabled={!selectedClassObj}
      >
        {selectedClassObj?.topics.map((t) => (
          <Select.Option key={t.id} value={t.id}>
            {t.name}
          </Select.Option>
        ))}
      </Select>

      {/* Subtopic Selection */}
      <Select
        style={{ width: 200 }}
        placeholder="Select Chapter"
        value={selectedSubtopic}
        onChange={handleSubtopicChange}
        disabled={!selectedTopicObj}
      >
        {selectedTopicObj?.subtopics.map((s) => (
          <Select.Option key={s.id} value={s.id}>
            {s.name}
          </Select.Option>
        ))}
      </Select>
    </Space>
  );
};

export default MultiLevelSelector;
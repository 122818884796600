import { motion, AnimatePresence } from "framer-motion";
import type { PathwayData } from "./types";
import { useState } from "react";
import { Hexagon } from "./components/Hexagon";
import { useTileConfig } from "./hooks/useTileConfig";

interface SubjectTileProps {
  data: PathwayData;
  color: string;
  onSelect: (data: PathwayData, subtopicId: string) => void;
}

export const SubjectTile = ({ data, color, onSelect }: SubjectTileProps) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const config = useTileConfig(data.subtopics.length);

  if (!data.subtopics?.length) {
    return null;
  }

  return (
    <div className="flex flex-col items-center gap-2">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        className="relative"
        style={config.containerStyle}
      >
        <AnimatePresence>
          {data.subtopics.map((subtopic, i) => (
            <div
              key={subtopic._id}
              className="absolute mt-20 hover:z-50"
              style={{
                top: config.tilePositions[i].top,
                left: config.tilePositions[i].left,
              }}
            >
              <Hexagon
                color={color}
                onClick={() => onSelect(data, subtopic?._id)}
                progress={subtopic?.pctComplete}
                name={subtopic?.name}
                index={i}
              />
            </div>
          ))}
        </AnimatePresence>
      </motion.div>
      <motion.div
        onClick={() => onSelect(data, data.subtopics[0]._id)}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        style={{ backgroundColor: color }}
        className="z-20 px-4 py-2 text-center font-medium backdrop-blur-md rounded-lg shadow-lg hover:shadow-xl transition-all duration-300 hover:bg-white/80 cursor-pointer text-white"
      >
        {data.name}
      </motion.div>
    </div>
  );
};

import { Card } from "../../../ui/card";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table";
import ProgressBar from "./ProgressIndicator";


interface IFoundationSkillData {
  skill: string;
  category: string;
  mastery: number;
}

interface FoundationSummaryProps {
  foundationData: {
    literacy: IFoundationSkillData[];
    numeracy: IFoundationSkillData[];
  };
}

function FoundationSummary({ foundationData }: FoundationSummaryProps) {
  const EmptyState = () => (
    <TableRow>
      <TableCell colSpan={3} className="h-24 text-center text-slate-500">
        No data available
      </TableCell>
    </TableRow>
  );

  return (
    <div className="flex flex-col gap-4">
      <h4 className="text-2xl font-bold text-slate-800 tracking-tight">
        Foundation Skills
      </h4>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
        <Card className="p-6 duration-300 border border-slate-200">
          <div className="mb-4 text-lg font-semibold text-indigo-600">
            Literacy Skills
          </div>
          <Table>
            <TableHeader>
              <TableRow className="bg-slate-50">
                <TableHead className="font-bold text-slate-700">
                  Skill
                </TableHead>
                {/* <TableHead className="font-bold text-slate-700">
                  Category
                </TableHead> */}
                <TableHead className="text-right font-bold text-slate-700">
                  Mastery
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {foundationData?.literacy?.length ? (
                foundationData.literacy.map((item, i) => (
                  <TableRow
                    key={i}
                    className="hover:bg-slate-50 transition-colors"
                  >
                    <TableCell className="font-medium text-slate-900">
                      {item.skill}
                    </TableCell>
                    {/* <TableCell className="text-slate-600">
                      {item.category}
                    </TableCell> */}
                    <TableCell className="text-right">
                      <ProgressBar d={item.mastery} />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <EmptyState />
              )}
            </TableBody>
          </Table>
        </Card>

        <Card className="p-6 border border-slate-200">
          <div className="mb-4 text-lg font-semibold text-emerald-600">
            Numeracy Skills
          </div>
          <Table>
            <TableHeader>
              <TableRow className="bg-slate-50">
                <TableHead className="font-bold text-slate-700">
                  Skill
                </TableHead>
                {/* <TableHead className="font-bold text-slate-700">
                  Category
                </TableHead> */}
                <TableHead className="text-right font-bold text-slate-700">
                  Mastery
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {foundationData?.numeracy?.length ? (
                foundationData.numeracy.map((item, i) => (
                  <TableRow
                    key={i}
                    className="hover:bg-slate-50 transition-colors"
                  >
                    <TableCell className="font-medium text-slate-900">
                      {item.skill}
                    </TableCell>
                    {/* <TableCell className="text-slate-600">
                      {item.category}
                    </TableCell> */}
                    <TableCell className="text-right">
                      <ProgressBar d={item.mastery} />
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <EmptyState />
              )}
            </TableBody>
          </Table>
        </Card>
      </div>
    </div>
  );
}

export default FoundationSummary;

import React from "react";
import { FileDown } from "lucide-react";
import { Button } from "antd";
import { version } from "os";

const ProfilingTemplateDownload = ({
  filename = "question-template.json",
  buttonText = "Download Sample Template",
}) => {
  const sampleQuestionBank = {
    Grade: "10",
    subject: "Physics",
    type: "mcq",
    Source: "NEET UG PYQ",
    Author: "you",
    curriculumBoard: "ICSE",
    isProfiling: true,
    version: "V2",
    questions: [
      {
        question:
          "The temperature at which Gibbs' energy change (ΔG) for the vaporization of water at 1 atmospheric pressure becomes zero is:",
        options: ["273.4 K", "373.4 K", "393.4 K", "1000 K"],
        answer: "373.4 K",
        explanation:
          "At this temperature, water changes from liquid to vapor phase at 1 atm pressure.",
        hint: "Consider the boiling point of water.",
        year: "2004",
        chapter: ["Machines"],
        conceptTags: [],
      },
      {
        question:
          "The values of ΔH and ΔS for the reaction C(graphite) + 1/2 O2(g) → CO2(g) are -393.3 kJ mol-1 and 213.8 J K-1 mol-1 respectively. The temperature at which this reaction will be spontaneous at equilibrium is:",
        options: ["500 K", "1000 K", "1250 K", "750 K"],
        answer: "500 K",
        explanation:
          "Using the Gibbs free energy equation, we can determine the temperature for spontaneity.",
        hint: "Remember the relationship between ΔG, ΔH, and ΔS.",
        year: "2004",
        chapter: ["Force and Motion"],
        conceptTags: [],
      },
    ],
  };

  const downloadTemplate = () => {
    const jsonString = JSON.stringify(sampleQuestionBank, null, 2);
    const blob = new Blob([jsonString], { type: "application/json" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");

    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <Button
      onClick={downloadTemplate}
      className="flex items-center gap-2 bg-blue-50 hover:bg-blue-100 border-blue-200"
    >
      <FileDown className="h-4 w-4 text-blue-600" />
      <span className="text-blue-700">{buttonText}</span>
    </Button>
  );
};

export default ProfilingTemplateDownload;

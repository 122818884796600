import { useParams } from "react-router-dom";
import { Result } from "antd";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../ui/tabs";
import { TabsTriggerProps } from "@radix-ui/react-tabs";
import { BookOpen, Loader2 } from "lucide-react";
import { useSelector } from "react-redux";
import { StudentDetails } from "./components/StudentDetails";
import { LearningGaps } from "./components/sections/LearningGaps";
import EngagementStatus from "./components/Engagement";
import { SkillGrowth } from "./components/sections/SkillGrowth";
import CompetencyReport from "./components/CompetencyReport";
import FoundationSummary from "./components/FoundationSummary";
import ProfileSummary from "./components/ProfileSummary";
import PriorLearningIndex from "./components/PriorLearningIndex";
import GradeProgress from "./components/GradeProgress";
import SocialEmotionalTraits from "./components/SocialEmotionalTraits";
import { Card } from "../../ui/card";
import { AcademicGrowth } from "./components/sections/AcademicGrowth";
import MasteryGrowthChart from "./components/ProfileSubjectMastery";
import SubjectPerformanceTable from "./components/SubjectMasteryDashboard";
import { RootState } from "../../app/store";
import { useGetStudentProfileQuery } from "../schools/SchoolsApiSlice";
import LearningGapsTable from "./components/LearningGapsTable";

export const EmptyStateCard = ({
  icon: Icon,
  title,
  message,
  iconColor,
  iconBgColor,
}: {
  icon: any;
  title: string;
  message: string;
  iconColor: string;
  iconBgColor: string;
}) => (
  <div className="flex flex-col items-center justify-center h-full py-8 px-4">
    <div className={`${iconBgColor} rounded-full p-4 mb-4`}>
      <Icon className={`w-8 h-8 ${iconColor}`} />
    </div>
    <h4 className="text-lg font-semibold text-slate-800 mb-2">{title}</h4>
    <p className="text-slate-500 text-center text-sm mb-4">{message}</p>
  </div>
);
export default function StudentProfile({ studentId, schoolId }: { studentId?: string; schoolId?:string}) {
  // const { id } = useParams<{ id: string }>();
  // const { user } = useSelector((state: RootState) => ({
  //   user: state.auth.user,
  // }));

//   const isDemo = user?.role?.includes("demo");
  const {
    data: studentData,
    isLoading,
    isError,
  } = useGetStudentProfileQuery({
    studentId,
    schoolId
  });

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader2 className="h-8 w-8 animate-spin text-primary" />
      </div>
    );
  }

  if (isError) {
    return (
      <Result
        status="error"
        title="Error loading student data"
        subTitle="Please try again later"
      />
    );
  }

  if (!studentData) {
    return <Result status="warning" title="Student Not Found" />;
  }
  const hasAcademicData = studentData?.academicGrowth?.subjectMastery;
  const hasCognitiveData = studentData?.cognitiveGrowth?.bloomsTaxonomy;
  return (
    <div className=" py-8 px-4 space-y-8 text-center flex flex-col justify-start">
      <ProfileSummary profileData={studentData?.profileSummary} />

      <Tabs defaultValue="overview" className="border rounded-lg p-4 bg-white">
        <TabsList className="w-full grid grid-cols-2 lg:grid-cols-5 gap-2 h-12">
          <TABBUTTON value="overview">Overview</TABBUTTON>
          <TABBUTTON value="foundation">Foundation</TABBUTTON>
          <TABBUTTON value="learning-gaps">Learning gaps</TABBUTTON>
          <TABBUTTON value="skills">21st Century Skills</TABBUTTON>
          <TABBUTTON value="engagement">Engagement</TABBUTTON>
          {/* <TABBUTTON value="social">Behavioural Traits</TABBUTTON> */}
        </TabsList>
        <TabsContent
          value="overview"
          className="mt-6 space-y-6 animate-in fade-in-50 slide-in-from-bottom-3"
        >
          <CompetencyReport competencyData={studentData?.competencyReport} />
          <div className="grid grid-cols-12 gap-6">
            <div className="col-span-7">
              <MasteryGrowthChart studentId={studentId} schoolId={schoolId} />
            </div>
            <div className="col-span-5">
              {hasAcademicData ? (
                <AcademicGrowth
                  subjectMastery={studentData?.academicGrowth?.subjectMastery}
                />
              ) : (
                <Card className="border border-slate-200">
                  <EmptyStateCard
                    icon={BookOpen}
                    title="No Academic Data"
                    message="Start tracking academic progress by adding subject mastery data"
                    iconColor="text-indigo-600"
                    iconBgColor="bg-indigo-50"
                  />
                </Card>
              )}
            </div>
          </div>
          <SubjectPerformanceTable data={studentData?.subjectPeformance} />
          <SkillGrowth skills={studentData?.skillGrowth} />
        </TabsContent>
        <TabsContent
          value="skills"
          className="mt-6  animate-in fade-in-50 slide-in-from-bottom-3"
        >
          <SkillGrowth skills={studentData?.skillGrowth} />
        </TabsContent>
        <TabsContent value="learning-gaps" className="mt-6 space-y-6">
          {/* <LearningGaps gaps={studentData?.learningGaps} /> */}
          <LearningGapsTable studentId={studentId} schoolId={schoolId} />
        </TabsContent>
        <TabsContent
          value="engagement"
          className="mt-6 space-y-6 animate-in fade-in-50 slide-in-from-bottom-3"
        >
          {studentData?.engagementStatus ? (
            <EngagementStatus engagementData={studentData?.engagementStatus} />
          ) : null}
        </TabsContent>

        <TabsContent
          value="foundation"
          className="mt-6 space-y-6 animate-in fade-in-50 slide-in-from-bottom-3"
        >
          <FoundationSummary foundationData={studentData?.foundationSummary} />

          {/* <GradeProgress gradeProgressData={studentData?.gradeProgress} /> */}
          {studentData?.priorLearningIndex && (
            <PriorLearningIndex
              priorLearningData={studentData?.priorLearningIndex}
            />
          )}
        </TabsContent>

        {/* <TabsContent
          value="social"
          className="mt-6 space-y-6 animate-in fade-in-50 slide-in-from-bottom-3"
        >
         
          <SocialEmotionalTraits
            socialEmotionalData={studentData?.socialEmotionalTraits}
          />
        </TabsContent> */}
      </Tabs>
    </div>
  );
}
 {/* {isDemo && (
            <PersonalisationMatrix
              matrixData={studentData?.personalisationMatrix}
            />
          )} */}






  // const student = mockStudents[0];
  // const mockEngagementData = {
  //   totalWorksheetsCompleted: 15,
  //   timeSpent: 240,
  //   totalQuestionsAnswered: 120,
  //   completionRate: 85,
  //   questionAttemptedTrend: [
  //     { date: "2024-01-01", value: 10 },
  //     { date: "2024-01-08", value: 15 },
  //     { date: "2024-01-15", value: 25 },
  //     { date: "2024-01-22", value: 20 },
  //     { date: "2024-01-29", value: 30 },
  //   ],
  //   courseWiseCompletion: [
  //     { subject: "Mathematics", completion: 90 },
  //     { subject: "Science", completion: 75 },
  //     { subject: "English", completion: 80 },
  //     { subject: "History", completion: 60 },
  //     { subject: "Geography", completion: 85 },
  //   ],
  // };
  
  
  // const mockEngagementDataEmpty = {
  //     totalWorksheetsCompleted: 0,
  //     timeSpent: 0,
  //     totalQuestionsAnswered: 0,
  //     completionRate: 0,
  //     questionAttemptedTrend: [],
  //     courseWiseCompletion: [],
  //   };
  
  // const mockEngagementDataManySubjects = {
  //   ...mockEngagementData,
  //   courseWiseCompletion: [
  //     { subject: "Mathematics", completion: 90 },
  //     { subject: "Science", completion: 75 },
  //     { subject: "English", completion: 80 },
  //     { subject: "History", completion: 60 },
  //     { subject: "Geography", completion: 85 },
  //     { subject: "Physics", completion: 70 },
  //     { subject: "Chemistry", completion: 65 },
  //     { subject: "Biology", completion: 80 },
  //     { subject: "Computer Science", completion: 95 },
  //     { subject: "Art", completion: 50 },
  
  
  //   ]
  // }
{/* <TABBUTTON value="learning">Learning gaps</TABBUTTON> */}
 {/* (<EngagementStatus engagementData={mockEngagementData} />)} */}
          
          {/* <EngagementStatus engagementData={studentData?.engagementStatus} /> */}
{/* <TabsContent
          value="learning"
          className="mt-6 space-y-6 animate-in fade-in-50 slide-in-from-bottom-3"
        >
          <LearningGaps gaps={student?.learningGaps} />
        </TabsContent> */}
// const TABBUTTON = ({ children, value, ...props }: TabsTriggerProps) => (
//   <TabsTrigger
//     value={value}
//     className="relative px-4 py-2 rounded-lg bg-gradient-to-r from-white to-purple-50 
//     hover:bg-gradient-to-r hover:from-purple-100 hover:to-violet-100
//     data-[state=active]:bg-gradient-to-br data-[state=active]:from-purple-500 data-[state=active]:to-violet-500 
//     transition-all duration-300 ease-in-out
//     text-purple-700 hover:text-purple-900 data-[state=active]:text-white
//     text-md font-medium shadow-sm hover:shadow-md
//     data-[state=active]:shadow-lg data-[state=active]:shadow-purple-200/50
//     data-[state=active]:scale-105 hover:scale-102
//     before:absolute before:inset-0 before:rounded-lg before:bg-gradient-to-r 
//     before:from-purple-200/20 before:to-violet-200/20 before:opacity-0
//     hover:before:opacity-100 before:transition-opacity"
//     {...props}
//   >
//     {children}
//   </TabsTrigger>
// );

const TABBUTTON = ({ children, value, ...props }: TabsTriggerProps) => (
  <TabsTrigger
    value={value}
    className="relative px-4 py-2 rounded-lg bg-gradient-to-r from-white to-purple-50/70 
    hover:bg-gradient-to-r hover:from-purple-50 hover:to-violet-50
    data-[state=active]:bg-gradient-to-br data-[state=active]:from-purple-500 data-[state=active]:to-violet-500 
    transition-all duration-300 ease-in-out
    text-purple-700 hover:text-purple-900 data-[state=active]:text-white
    text-md font-medium shadow-sm hover:shadow-md
    data-[state=active]:shadow-lg data-[state=active]:shadow-purple-200/50
    data-[state=active]:scale-105 hover:scale-102
    before:absolute before:inset-0 before:rounded-lg before:bg-gradient-to-r 
    before:from-purple-200/10 before:to-violet-200/10 before:opacity-0
    hover:before:opacity-100 before:transition-opacity"
    {...props}
  >
    {children}
  </TabsTrigger>
);

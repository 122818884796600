import { Card } from "../../../../ui/card";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../ui/Dialog";
import {
  Lightbulb,
  BookOpen,
  ChevronDown,
  Target,
  Brain,
  Search,
  Trophy,
  Sparkles,
  Plus,
} from "lucide-react";
import { useState } from "react";
import type { Module, SkillProgress } from "../../types";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "../../../../ui/collapsible";
import { motion, AnimatePresence } from "framer-motion";

const sampleSkills: SkillProgress[] = [
  {
    skill: "Critical Thinking",
    completedModules: 6,
    totalModules: 8,
    progress: 75,
    icon: Brain,
    modules: [
      {
        id: "ct1",
        name: "Analytical Reasoning",
        status: "completed",
        completedAt: "2024-01-15",
        completedLessons: 5,
        totalLessons: 5,
        lessons: [
          {
            id: "ct1l1",
            name: "Introduction to Logic",
            duration: 30,
            status: "completed",
            completedAt: "2024-01-10",
          },
          {
            id: "ct1l2",
            name: "Pattern Recognition",
            duration: 45,
            status: "completed",
            completedAt: "2024-01-12",
          },
        ],
      },
      {
        id: "ct2",
        name: "Problem Solving",
        status: "in-progress",
        completedLessons: 2,
        totalLessons: 4,
        lessons: [
          {
            id: "ct2l1",
            name: "Problem Identification",
            duration: 30,
            status: "completed",
            completedAt: "2024-01-18",
          },
          {
            id: "ct2l2",
            name: "Solution Generation",
            duration: 45,
            status: "in-progress",
          },
        ],
      },
    ],
  },
  {
    skill: "Research Skills",
    completedModules: 4,
    totalModules: 6,
    progress: 66,
    icon: Search,
    modules: [
      {
        id: "rs1",
        name: "Information Literacy",
        status: "completed",
        completedAt: "2024-01-20",
        completedLessons: 3,
        totalLessons: 3,
        lessons: [
          {
            id: "rs1l1",
            name: "Source Evaluation",
            duration: 40,
            status: "completed",
            completedAt: "2024-01-18",
          },
          {
            id: "rs1l2",
            name: "Academic Databases",
            duration: 35,
            status: "completed",
            completedAt: "2024-01-19",
          },
        ],
      },
    ],
  },
  {
    skill: "Goal Setting",
    completedModules: 3,
    totalModules: 5,
    progress: 60,
    icon: Target,
    modules: [
      {
        id: "gs1",
        name: "SMART Goals",
        status: "completed",
        completedAt: "2024-01-25",
        completedLessons: 4,
        totalLessons: 4,
        lessons: [
          {
            id: "gs1l1",
            name: "Goal Framework",
            duration: 25,
            status: "completed",
            completedAt: "2024-01-22",
          },
          {
            id: "gs1l2",
            name: "Action Planning",
            duration: 30,
            status: "completed",
            completedAt: "2024-01-24",
          },
        ],
      },
    ],
  },
];

function ModuleCard({ module }: { module: Module }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Collapsible
      open={isOpen}
      onOpenChange={setIsOpen}
      className="bg-white rounded-lg border shadow-sm hover:shadow-md transition-all duration-300"
    >
      <CollapsibleTrigger className="w-full">
        <div className="p-4 flex items-center justify-between">
          <div className="space-y-1">
            <div className="flex items-center gap-2">
              <h4 className="font-medium text-gray-900">{module?.name}</h4>
            </div>
            <div className="text-xs text-gray-500">
              {module?.completedLessons}/{module?.totalLessons} lessons
              completed
            </div>
          </div>
          <div className="flex items-center gap-2">
            <span
              className={`w-2 h-2 rounded-full transition-colors duration-300 ${
                module?.status === "completed"
                  ? "bg-green-500"
                  : module?.status === "in-progress"
                  ? "bg-blue-500"
                  : "bg-gray-300"
              }`}
            />
            <ChevronDown
              className={`w-4 h-4 transition-transform duration-300 ease-in-out ${
                isOpen ? "transform rotate-180" : ""
              }`}
            />
          </div>
        </div>
      </CollapsibleTrigger>

      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{
              height: "auto",
              opacity: 1,
              transition: {
                height: { duration: 0.1 },
                opacity: { duration: 0.2 },
              },
            }}
            exit={{
              height: 0,
              opacity: 0,
              transition: {
                height: { duration: 0.1 },
                opacity: { duration: 0.2 },
              },
            }}
          >
            <CollapsibleContent>
              <div className="border-t">
                <div className="px-4 py-2 space-y-2">
                  {module?.lessons?.map((lesson) => (
                    <div
                      key={lesson?.id}
                      className="flex items-center justify-between p-2 rounded-md text-sm hover:bg-gray-50 transition-all duration-200"
                    >
                      <div className="flex items-center gap-2">
                        <BookOpen className="w-4 h-4 text-gray-400" />
                        <span>{lesson?.name}</span>
                      </div>
                      <div className="flex items-center gap-2">
                        <span className="text-xs text-gray-500">
                          {lesson?.duration}min
                        </span>
                        <span
                          className={`px-2 py-1 rounded-full text-xs font-medium transition-colors duration-300 ${
                            lesson?.status === "completed"
                              ? "bg-green-100 text-green-700"
                              : lesson?.status === "in-progress"
                              ? "bg-blue-100 text-blue-700"
                              : "bg-gray-100 text-gray-700"
                          }`}
                        >
                          {lesson?.status === "completed"
                            ? "Done"
                            : lesson?.status === "in-progress"
                            ? "In Progress"
                            : "Not Started"}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </CollapsibleContent>
          </motion.div>
        )}
      </AnimatePresence>
    </Collapsible>
  );
}
export function SkillModal({
  skill,
  isOpen,
  onClose,
}: {
  skill: SkillProgress | null;
  isOpen: boolean;
  onClose: () => void;
}) {
  if (!skill) return null;

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="max-w-2xl max-h-[80vh] overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="flex items-center gap-2">
            <span>{skill?.skill}</span>
            <span className="text-sm font-normal text-yellow-600">
              ({skill?.completedModules}/{skill?.totalModules} modules)
            </span>
          </DialogTitle>
        </DialogHeader>
        <div className="space-y-4 mt-4">
          {skill?.modules?.map((module) => (
            <ModuleCard key={module?.id} module={module} />
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}

function DonutChart({ progress }: { progress: number }) {
  const size = 64;
  const strokeWidth = 5;
  const radius = (size - strokeWidth) / 2;
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset =
    circumference - ((progress || 0) / 100) * circumference;

  return (
    <div className="relative w-20 h-20 flex items-center justify-center">
      <svg width={size} height={size} className="transform -rotate-90">
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke="#FEF3C7"
          strokeWidth={strokeWidth}
          className="opacity-40"
        />
        <circle
          cx={size / 2}
          cy={size / 2}
          r={radius}
          fill="none"
          stroke="currentColor"
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
          strokeLinecap="round"
          className="text-yellow-500 transition-all duration-300"
        />
      </svg>
      <div className="absolute flex flex-col items-center">
        <span className="text-lg font-bold text-yellow-700">{progress}%</span>
      </div>
    </div>
  );
}

export function SkillGrowth({ skills }: { skills?: SkillProgress[] }) {
  const [selectedSkill, setSelectedSkill] = useState<SkillProgress | null>(
    null
  );

  if (!skills?.length) {
    return (
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h3 className="text-lg font-semibold flex items-center gap-2 text-yellow-700">
            <Lightbulb className="w-5 h-5" />
            Skills Gained
          </h3>
          <span className="text-sm text-gray-500">0 modules completed</span>
        </div>

        <Card className="p-8">
          <div className="flex flex-col items-center justify-center text-center">
            <div className="flex items-center justify-center space-x-3 mb-6">
              <div className="bg-yellow-50 rounded-full p-3">
                <Trophy className="w-6 h-6 text-yellow-600" />
              </div>
              <div className="bg-amber-50 rounded-full p-3">
                <Sparkles className="w-6 h-6 text-amber-600" />
              </div>
              <div className="bg-orange-50 rounded-full p-3">
                <Lightbulb className="w-6 h-6 text-orange-600" />
              </div>
            </div>

            <h4 className="text-lg font-semibold text-gray-900 mb-2">
              Start Your Skill Journey
            </h4>
            <p className="text-gray-500 max-w-sm mb-6">
              Tracking will begin once students start acquiring skills, which
              can then be organized into learning modules.
            </p>

            {/* <button className="inline-flex items-center px-4 py-2 rounded-full bg-gradient-to-r from-yellow-500 to-amber-500 text-white hover:from-yellow-600 hover:to-amber-600 transition-all duration-200 shadow-sm">
              <Plus className="w-4 h-4 mr-2" />
              Add Your First Skill
            </button> */}
          </div>
        </Card>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h3 className="text-lg font-semibold flex items-center gap-2 text-yellow-700">
          <Lightbulb className="w-5 h-5" />
          Skills Gained
        </h3>
        <span className="text-sm text-gray-500">
          {skills?.reduce(
            (acc, skill) => acc + (skill?.completedModules || 0),
            0
          )}{" "}
          modules completed
        </span>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {skills?.map((skill) => (
          <Card
            key={skill?.skill}
            className="overflow-hidden cursor-pointer hover:shadow-md transition-all duration-200 group"
            onClick={() => setSelectedSkill(skill)}
          >
            <div className="p-6 flex flex-col items-center space-y-4">
              <div className="relative">
                <DonutChart progress={skill?.progress || 0} />
              </div>
              <div className="text-center space-y-1">
                <div className="flex items-center gap-2">
                  {skill?.icon && (
                    <skill.icon className="w-6 h-6 text-yellow-600" />
                  )}
                  <h4 className="font-semibold text-gray-900 group-hover:text-yellow-600 transition-colors duration-200">
                    {skill?.skill}
                  </h4>
                </div>
                <div className="text-xs font-medium px-2 py-1 rounded-full bg-yellow-100 text-yellow-700 inline-block">
                  {skill?.completedModules}/{skill?.totalModules} modules
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>

      <SkillModal
        skill={selectedSkill}
        isOpen={!!selectedSkill}
        onClose={() => setSelectedSkill(null)}
      />
    </div>
  );
}

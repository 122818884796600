import { useEffect, useRef } from "react";

import type { PathwayData, SubtopicType } from "./types";
import { motion } from "framer-motion";
import { BookOpen, Clock, Star, ChevronRight } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "../../ui/Dialog";
import { Card } from "../../ui/card";
import { Button } from "../../ui/button";

interface SubjectDetailsProps {
  selectedPathway: PathwayData | null;
  selectedSubtopicId: string | null;
  isRevisionContentAvailable: boolean;
  onClose: () => void;
  color: string;
}

// Header Component
const PathwayHeader = ({
  name,
  rating,
  description,
  color,
}: {
  name: string;
  rating: number;
  description: string;
  color: string;
}) => (
  <DialogHeader className="pb-8">
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-6">
        <DialogTitle
          className="text-3xl font-bold tracking-tight text-transparent"
          style={{
            backgroundImage: `linear-gradient(to right, ${color}, ${color}cc)`,
            WebkitBackgroundClip: "text",
          }}
        >
          {name}
        </DialogTitle>
      </div>
      <div className="flex items-center gap-2">
        <Star className="w-5 h-5 text-yellow-400 fill-yellow-400" />
        <span className="text-yellow-400 font-bold">{rating}</span>
      </div>
    </div>
    <DialogDescription className="text-lg font-medium text-slate-300/90">
      {description}
    </DialogDescription>
  </DialogHeader>
);

// Progress Hexagon Component
const ProgressHexagon = ({
  color,
  pctComplete,
}: {
  color: string;
  pctComplete: number;
}) => (
  <div className="flex-shrink-0 gap-2 flex flex-col items-center">
    <div
      className="relative w-16 h-16 flex-shrink-0 self-center"
      style={{
        clipPath:
          "polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%)",
      }}
    >
      <div className="absolute inset-0" style={{ backgroundColor: color }} />
      <div
        className="absolute inset-0 bg-gray-200/40"
        style={{
          height: `${100 - (pctComplete || 0)}%`,
          top: 0,
        }}
      />
    </div>
    <div className="inset-0 flex items-center justify-center text-white font-bold">
      {pctComplete}%
    </div>
  </div>
);

// Subtopic Card Component
const SubtopicCard = ({
  isRevisionContentAvailable,
  subtopic,
  color,
  isSelected,
  onClick,
}: {
  isRevisionContentAvailable: boolean;
  subtopic: SubtopicType;
  color: string;
  isSelected: boolean;
  onClick: () => void;
}) => (
  <motion.div>
    <Card
      className={`group p-6 hover:shadow-xl transition-all duration-300 bg-slate-800/50 hover:border-opacity-30 ${
        isSelected ? `ring-2 ring-offset-2 ring-[${color}] animate-flash` : ""
      }`}
      style={{ borderColor: `${color}1a` }}
    >
      <div className="flex gap-6">
        <ProgressHexagon color={color} pctComplete={subtopic?.pctComplete} />
        <div className="flex flex-col gap-4 flex-grow">
          <div className="flex items-center gap-3">
            <BookOpen style={{ color }} className="w-6 h-6" />
            <h4
              className="text-xl font-bold text-transparent"
              style={{
                backgroundImage: `linear-gradient(to right, ${color}, ${color}cc)`,
                WebkitBackgroundClip: "text",
              }}
            >
              {subtopic?.name}
            </h4>
          </div>
          <p className="text-base text-slate-300 leading-relaxed">
            {subtopic?.description}
          </p>
          <div className="flex items-center justify-between pt-2">
            <div className="flex items-center gap-2">
              <Clock className="w-4 h-4 text-slate-400" />
              <span className="text-sm text-slate-400">
                {subtopic?.totalReads} reads
              </span>
            </div>
            {isRevisionContentAvailable && (
              <Button
                variant="outline"
                className="transition-all duration-300 text-white group-hover:scale-105 shadow-lg flex items-center gap-2"
                onClick={onClick}
                style={
                  {
                    backgroundColor: `${color}1a`,
                    color: color,
                    ["--hover-color"]: color,
                  } as React.CSSProperties
                }
              >
                {subtopic?.learningStatus === "in-progress" ? (
                  <>
                    Continue <Clock className="w-4 h-4" />
                  </>
                ) : (
                  <>
                    Start Learning <ChevronRight className="w-4 h-4" />
                  </>
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Card>
  </motion.div>
);

// Main Component
export function SubjectDetails({
  selectedPathway,
  selectedSubtopicId,
  isRevisionContentAvailable,
  onClose,
  color,
}: SubjectDetailsProps) {
  const navigate = useNavigate();
  if (!selectedPathway) return null;
  return (
    <Dialog open={!!selectedPathway} onOpenChange={onClose}>
      <DialogContent
        className="max-w-4xl h-[85vh] overflow-y-auto rounded-xl border-t-2 bg-gradient-to-b from-slate-900 via-slate-900/95 to-slate-900/90"
        style={{ borderColor: color }}
      >
        <PathwayHeader
          name={selectedPathway?.name}
          rating={selectedPathway?.rating}
          description={selectedPathway?.description}
          color={color}
        />

        <div className="grid gap-6 py-4">
          {selectedPathway?.subtopics?.map((subtopic: SubtopicType) => (
            <SubtopicCard
              isRevisionContentAvailable={isRevisionContentAvailable}
              key={subtopic?._id}
              subtopic={subtopic}
              color={color}
              isSelected={selectedSubtopicId === subtopic?._id}
              onClick={() =>
                navigate(
                  `/student-dashboard/revise/${selectedPathway?._id}/${subtopic?._id}`
                )
              }
            />
          ))}
        </div>
      </DialogContent>
    </Dialog>
  );
}

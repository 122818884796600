import { apiSlice } from "../../app/api/apiSlice";
import { SchoolDetail, TeachersOverview } from "./types";

interface RemedialActivityRequest {
  studentIds: string[];
  title?: string;
  description?: string;
  scope: {
    topicIds?: string[];
    subtopicIds?: string[];
    conceptIds?: string[];
  };
  options: {
    override?: boolean;
    count?: number;
  };
}

const schoolsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSchools: builder.query<any, void>({
      query: () => "/schools",
    }),
    teacherTracker: builder.query<any, any>({
      query: ({ schoolId, date }) => ({
        url: `/teachers-tracker?schoolId=${encodeURIComponent(schoolId)}&date=${encodeURIComponent(date)}`,
        method: "GET",
      }),
      
    }),
    teachersData: builder.query<any, any>({
      query: ({ schoolId, fromDate,toDate }) => ({
        url: `/get-teachers-data?schoolId=${encodeURIComponent(schoolId)}&fromDate=${encodeURIComponent(fromDate)}&toDate=${encodeURIComponent(toDate)}`,
        method: "GET",
      }),
      
    }),
    getActivityByParamsGroupedByTeachers: builder.query({
      query: ({fromDate, toDate,schoolId}) => ({
        url: `/activities/classes?activitiesFromDate=${fromDate}&activitiesToDate=${toDate}&schoolId=${schoolId}`,
        method: "GET",
      }),
    }),
    getSchoolDetails: builder.query<SchoolDetail, string>({
      query: (id) => `/schools/${id}`,
    }),
    getTeacherPerformance: builder.query<TeachersOverview, string>({
      query: (id) => `/schools/${id}/teachers`,
    }),
    impersonateSchool: builder.mutation<any, string>({
      query: (schoolId) => ({
        url: "/impersonate-school",
        method: "POST",
        body: { schoolId },
      }),
    }),
    getStudentsForSchool: builder.query<any[], string>({ // Use correct types
      query: (schoolId:string) => `school/${schoolId}/students`,
    }),
    createIndividualActivityForStudent: builder.mutation({
      query: ({ activityData, studentIds, bloomLevels }) => ({
        url: `activity/students`, // Correct URL
        method: "POST",
        body: { 
          ...activityData, // Send activityData as a nested object
          bloomLevels: bloomLevels,    //  or bloomLevel (no spread needed here)
          studentIds:studentIds
        },
      }),
    }),
    createRemedialActivity: builder.mutation<any, RemedialActivityRequest>({
      query: (remedialActivityData) => ({
        url: `activity/remedial`,  
        method: "POST",
        body: remedialActivityData,
      }),
    }),
    publishActivity: builder.mutation<any,any>({
      query: (body) => ({
        url: `activity/publish`, // Use the correct URL from your backend
        method: "POST",
        body,  // You can directly pass the 'body' argument
      }),
    }),
    getStudentProfile: builder.query<any, { schoolId?: string; studentId?: string }>({
      query: ({ schoolId, studentId }) => `/school/${schoolId}/students/${studentId}/profile`,
    }),
    
    getStudentMasteryProgress: builder.query<any, any>({
      query: ({ schoolId, studentId }) => `/school/${schoolId}/students/${studentId}/mastery/progress`,
    }),
    getStudentLearningGaps: builder.query<any, any>({
      query: ({ schoolId, studentId }) => `/school/${schoolId}/students/${studentId}/learning-gaps`,
    }),
    getPathways: builder.query({
      query: (studentId) => `/pathways/${studentId}`,
    }),
  }),
});

export const {
  useGetSchoolsQuery,
  useGetSchoolDetailsQuery,
  useGetTeacherPerformanceQuery,
  useImpersonateSchoolMutation,
  useTeacherTrackerQuery,
  useTeachersDataQuery,
  useGetActivityByParamsGroupedByTeachersQuery,
  useGetStudentsForSchoolQuery,
  useCreateIndividualActivityForStudentMutation,
  useCreateRemedialActivityMutation,
  usePublishActivityMutation,
  useGetStudentProfileQuery,
  useGetStudentMasteryProgressQuery,
  useGetStudentLearningGapsQuery,
  useGetPathwaysQuery
} = schoolsApiSlice;

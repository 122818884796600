import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../ui/Dialog";
import { Button } from "../../../ui/button";
import { BookOpen, Trophy, Activity, ArrowUpRight } from "lucide-react";
import { Card, CardHeader, CardTitle } from "../../../ui/card";
import InfoTooltip from "./InfoTooltip";

// Updated mock data with chapters
const mockSubjects = [
  {
    "id": 1,
    "name": "Math",
    "mastery": 88,
    "progress": 93,
    "rank": 2,
    "weakConcepts": [
      {
        "concept": "Geometry",
        "chapter": "Coordinate Geometry",
        "mastery": 52,
        "growth": 15
      },
      {
        "concept": "Algebra",
        "chapter": "Linear Equations",
        "mastery": 58,
        "growth": 12
      },
      {
        "concept": "Statistics",
        "chapter": "Probability",
        "mastery": 45,
        "growth": 18
      }
    ]
  },
  {
    "id": 2,
    "name": "Science",
    "mastery": 85,
    "progress": 90,
    "rank": 3,
    "weakConcepts": [
      {
        "concept": "Chemical Reactions",
        "chapter": "Chemical Changes",
        "mastery": 48,
        "growth": 16
      },
      {
        "concept": "Forces & Motion",
        "chapter": "Newton's Laws",
        "mastery": 55,
        "growth": 13
      },
      {
        "concept": "Life Processes",
        "chapter": "Biology Systems",
        "mastery": 50,
        "growth": 14
      }
    ]
  },
  {
    "id": 3,
    "name": "English",
    "mastery": 82,
    "progress": 87,
    "rank": 4,
    "weakConcepts": [
      {
        "concept": "Grammar Usage",
        "chapter": "Advanced Grammar",
        "mastery": 54,
        "growth": 12
      },
      {
        "concept": "Writing Skills",
        "chapter": "Essay Writing",
        "mastery": 48,
        "growth": 15
      },
      {
        "concept": "Comprehension",
        "chapter": "Reading Analysis",
        "mastery": 51,
        "growth": 13
      }
    ]
  },
  {
    "id": 4,
    "name": "Social Science",
    "mastery": 80,
    "progress": 86,
    "rank": 5,
    "weakConcepts": [
      {
        "concept": "World History",
        "chapter": "Modern Era",
        "mastery": 45,
        "growth": 17
      },
      {
        "concept": "Economics",
        "chapter": "Macro Economics",
        "mastery": 50,
        "growth": 14
      },
      {
        "concept": "Geography",
        "chapter": "Physical Geography",
        "mastery": 52,
        "growth": 12
      }
    ]
  }
]



// Badge Components
const MasteryBadge = ({ value }:any) => (
  <div className="inline-flex items-center rounded-full px-2.5 py-1 bg-blue-50 border border-blue-200">
    <span className="text-sm font-medium text-blue-700">{value}%</span>
  </div>
);

const GrowthBadge = ({ value }:any) => (
  <div className="inline-flex items-center gap-1 rounded-full px-2.5 py-1 bg-emerald-50 border border-emerald-200">
    <ArrowUpRight size={14} className="text-emerald-600" />
    <span className="text-sm font-medium text-emerald-700">+{value}%</span>
  </div>
);

const ProgressBadge = ({ value }:any) => (
  <div className="inline-flex items-center rounded-full px-2.5 py-1 bg-emerald-50 border border-emerald-200">
    <span className="text-sm font-medium text-emerald-700">{value}%</span>
  </div>
);

const RankBadge = ({ value }:any) => (
  <div className="inline-flex items-center gap-1.5 rounded-full px-2.5 py-1 bg-purple-50 border border-purple-200">
    <Trophy size={14} className="text-purple-600" />
    <span className="text-sm font-medium text-purple-700">#{value}</span>
  </div>
);

const SubjectPerformanceTable = ({ data}:any) => {
  const [selectedConcepts, setSelectedConcepts] = useState<any>(null);
  const [selectedSubject, setSelectedSubject] = useState<any>(null);

  return (
    <Card className="w-full border-none ">
      <div className="bg-white rounded-xl overflow-hidden px-3">
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-7 translate-x-[-20px] " >
          <CardTitle className="text-xl font-bold flex items-center">
            Subject Performance Breakdown
            <InfoTooltip description="Detailed view of student's performance in each subject showing progress, mastery level, class ranking, and identified weak areas." trend={false} />
          </CardTitle>
        </CardHeader>
        <Table>
          <TableHeader>
            <TableRow className="border-none">
              <TableHead className="w-[25%] bg-gray-50/50 text-gray-600 font-medium py-4">
                Subject
              </TableHead>
              <TableHead className="w-[15%] bg-gray-50/50 text-gray-600 font-medium py-4">
                Mastery
              </TableHead>
              <TableHead className="w-[15%] bg-gray-50/50 text-gray-600 font-medium py-4">
                Progress
              </TableHead>
              <TableHead className="w-[15%] bg-gray-50/50 text-gray-600 font-medium py-4 text-center">
                Rank
              </TableHead>
              <TableHead className="w-[30%] bg-gray-50/50 text-gray-600 font-medium py-4 text-center">
                Weak Concepts
              </TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {data?.map((subject:any, index:any) => (
              <TableRow
                key={subject.id}
                className={`
                  hover:bg-blue-50/30 transition-colors duration-150
                  ${index !== data.length - 1 ? "border-b border-gray-100" : ""}
                `}
              >
                <TableCell className="py-4">
                  <div className="flex items-center gap-3">
                    <div className="w-10 h-10 rounded-lg bg-blue-100/50 flex items-center justify-center">
                      <BookOpen size={20} className="text-blue-600" />
                    </div>
                    <span className="font-medium text-gray-900">
                      {subject.name}
                    </span>
                  </div>
                </TableCell>
                <TableCell className="py-4">
                  <MasteryBadge value={subject.mastery} />
                </TableCell>
                <TableCell className="py-4">
                  <ProgressBadge value={subject.progress} />
                </TableCell>
                <TableCell className="py-4 text-center">
                  <RankBadge value={subject.rank} />
                </TableCell>
                <TableCell className="py-4 text-center">
                  <Button
                    variant="outline"
                    onClick={() => {
                      setSelectedConcepts(subject.weakConcepts);
                      setSelectedSubject(subject.name);
                    }}
                    className="bg-orange-50 border-orange-200 hover:bg-orange-100 text-orange-700"
                  >
                    {subject.weakConcepts.length} concepts identified
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>

      <Dialog
        open={!!selectedConcepts}
        onOpenChange={() => {
          setSelectedConcepts(null);
          setSelectedSubject(null);
        }}
      >
        <DialogContent className="max-w-4xl">
          <DialogHeader>
            <DialogTitle className="text-xl font-semibold flex items-center gap-2">
              <Activity className="text-orange-600 w-5 h-5" />
              Weak Concepts - {selectedSubject}
            </DialogTitle>
          </DialogHeader>
          <Table>
            <TableHeader>
              <TableRow className="border-none">
                <TableHead className="w-[30%] bg-gray-50/50 text-gray-600 font-medium">
                  Concept
                </TableHead>
                <TableHead className="w-[30%] bg-gray-50/50 text-gray-600 font-medium">
                  Chapter
                </TableHead>
                <TableHead className="w-[20%] bg-gray-50/50 text-gray-600 font-medium text-center">
                  Mastery
                </TableHead>
                <TableHead className="w-[20%] bg-gray-50/50 text-gray-600 font-medium text-center">
                  Growth
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {selectedConcepts?.map((concept:any, index:any) => (
                <TableRow
                  key={index}
                  className={`
                    hover:bg-orange-50/30 transition-colors duration-150
                    ${
                      index !== selectedConcepts.length - 1
                        ? "border-b border-gray-100"
                        : ""
                    }
                  `}
                >
                  <TableCell className="py-4">
                    <div className="flex items-center gap-3">
                      <div className="w-8 h-8 rounded-lg bg-orange-50 flex items-center justify-center">
                        <BookOpen size={16} className="text-orange-600" />
                      </div>
                      <span className="font-medium text-gray-900">
                        {concept.concept}
                      </span>
                    </div>
                  </TableCell>
                  <TableCell className="py-4">
                    <span className="text-gray-600">{concept.chapter}</span>
                  </TableCell>
                  <TableCell className="py-4 text-center">
                    <MasteryBadge value={concept.mastery} />
                  </TableCell>
                  <TableCell className="py-4 text-center">
                    <GrowthBadge value={concept.growth} />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
    </Card>
  );
};

export default SubjectPerformanceTable;

import { BookOpen } from "lucide-react";
import { SubjectMastery } from "../../types";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Cell,
} from "recharts";
import { Card } from "../../../../ui/card";

type Props = {
  subjectMastery: SubjectMastery[];
};

export function AcademicGrowth({ subjectMastery }: Props) {
  // Custom tooltip component
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-3 shadow-lg rounded-lg border border-gray-100">
          <p className="font-medium text-sm text-gray-700">{label}</p>
          <p className="text-sm text-gray-600">Mastery: {payload[0].value}%</p>
          <p className="text-sm">
            Growth:{" "}
            <span
              className={
                payload[0].payload.growth >= 0
                  ? "text-emerald-500"
                  : "text-rose-500"
              }
            >
              {payload[0].payload.growth >= 0 ? "+" : ""}
              {payload[0].payload.growth}%
            </span>
          </p>
        </div>
      );
    }
    return null;
  };

  return (
    <Card className="p-6 pb-2.5 border-none  bg-white">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-xl font-semibold flex items-center gap-2 text-blue-600">
          <BookOpen className="w-5 h-5" />
          Academic Growth
        </h3>
      </div>

      <div className="h-[410px] w-full ">
        <div className="flex items-center justify-center gap-4 text-sm text-gray-500">
          <div className="flex items-center gap-1">
            <div className="w-3 h-3 rounded-full bg-blue-500 bg-opacity-80"></div>
            <span>Positive Growth</span>
          </div>
          <div className="flex items-center gap-1">
            <div className="w-3 h-3 rounded-full bg-blue-300 bg-opacity-80"></div>
            <span>Negative Growth</span>
          </div>
        </div>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={subjectMastery}
            margin={{ top: 20, right: 30, left: 20, bottom: 25 }}
          >
            <CartesianGrid strokeDasharray="3 3" opacity={0.1} />
            <XAxis
              dataKey="subject"
              tick={{ fontSize: 12, fill: "#6B7280" }}
              axisLine={{ stroke: "#E5E7EB" }}
            />
            <YAxis
              domain={[0, 100]}
              tickFormatter={(value) => `${value}%`}
              tick={{ fontSize: 12, fill: "#6B7280" }}
              axisLine={{ stroke: "#E5E7EB" }}
              label={{
                value: "Mastery (%)",
                angle: -90,
                position: "insideLeft",
                style: { fontSize: 12, fill: "#6B7280" },
                offset: -5,
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar dataKey="currentMastery" radius={[6, 6, 0, 0]}>
              {subjectMastery?.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={entry.growth >= 0 ? "#3B82F6" : "#93C5FD"} // Changed to blue shades
                  opacity={0.9}
                />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </Card>
  );
}

// import { Card } from "@/components/ui/card";
// import { BookOpen } from "lucide-react";
// import { SubjectMastery } from "../../types";

// type Props = {
//   subjectMastery: SubjectMastery[];
// };

// export function AcademicGrowth({ subjectMastery }: Props) {
//   return (
//     <Card className="p-6 border-none shadow-md bg-white">
//       <h3 className="text-lg font-semibold mb-6 flex items-center gap-2 text-indigo-700">
//         <BookOpen className="w-5 h-5" />
//         Academic Growth
//       </h3>
//       <div className="space-y-6">
//         {subjectMastery?.map((subject) => (
//           <div key={subject?.subject} className="space-y-2">
//             <div className="flex justify-between">
//               <span className="text-sm font-medium">{subject?.subject}</span>
//               <div className="flex items-center gap-2">
//                 <span className="text-sm font-bold">
//                   {subject?.currentMastery}%
//                 </span>
//                 <span
//                   className={`text-sm font-medium ${
//                     subject.growth >= 0 ? "text-green-600" : "text-red-600"
//                   }`}
//                 >
//                   ({subject?.growth >= 0 ? "+" : ""}
//                   {subject?.growth}%)
//                 </span>
//               </div>
//             </div>
//             <div className="h-2 bg-indigo-100 rounded-full overflow-hidden">
//               <div
//                 className="h-full bg-gradient-to-r from-indigo-500 to-indigo-600 rounded-full transition-all duration-500"
//                 style={{ width: `${subject?.currentMastery}%` }}
//               />
//             </div>
//           </div>
//         ))}
//       </div>
//     </Card>
//   );
// }

import React, { useState, useEffect } from 'react';

import {
  Lightbulb,
  Brain,
  Target,
  TrendingUp,
  Gauge,
  HandMetal,
  Zap,
  Award,
  Crown,
  Info
} from "lucide-react";
import { Card, CardContent } from '../../../ui/card';

type CompetencyReportProps = {
  competencyData: { key: string; value: string }[] | undefined;
};

type CompetencyCardProps = {
  name: string;
  value: string;
  index: number;
};

const CompetencyCard = ({ name, value, index }: CompetencyCardProps) => {
  const [showInfo, setShowInfo] = useState(false);
  const [isHovering, setIsHovering] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isHovering) {
      timer = setTimeout(() => {
        setShowInfo(true);
      }, 600); // 600ms delay before showing tooltip
    } else {
      setShowInfo(false);
    }
    return () => clearTimeout(timer);
  }, [isHovering]);

  const colorVariants = [
    {
      bg: "bg-gradient-to-br from-violet-500/10 via-purple-500/10 to-fuchsia-500/10",
      text: "text-violet-700",
      shadow: "shadow-violet-100",
      highlight: "bg-violet-500",
      info: "Demonstrates innovative thinking and creative problem-solving abilities",
      icon: Lightbulb
    },
    {
      bg: "bg-gradient-to-br from-blue-500/10 via-cyan-500/10 to-teal-500/10",
      text: "text-blue-700",
      shadow: "shadow-blue-100",
      highlight: "bg-blue-500",
      info: "Shows technical proficiency and depth of knowledge",
      icon: Brain
    },
    {
      bg: "bg-gradient-to-br from-rose-500/10 via-pink-500/10 to-fuchsia-500/10",
      text: "text-rose-700",
      shadow: "shadow-rose-100",
      highlight: "bg-rose-500",
      info: "Indicates performance efficiency and goal achievement rate",
      icon: Gauge
    },
    {
      bg: "bg-gradient-to-br from-amber-500/10 via-orange-500/10 to-yellow-500/10",
      text: "text-amber-700",
      shadow: "shadow-amber-100",
      highlight: "bg-amber-500",
      info: "Reflects continuous improvement and growth trajectory",
      icon: TrendingUp
    },
    {
      bg: "bg-gradient-to-br from-emerald-500/10 via-green-500/10 to-teal-500/10",
      text: "text-emerald-700",
      shadow: "shadow-emerald-100",
      highlight: "bg-emerald-500",
      info: "Measures overall mastery and expertise level",
      icon: Crown
    }
  ];

  const getIcon = (index: number) => {
    const IconComponent = colorVariants[index % colorVariants.length].icon;
    return (
      <IconComponent
        className={`w-5 h-5 ${variant.text}`}
        strokeWidth={1.5}
      />
    );
  };

  const variant = colorVariants[index % colorVariants.length];

  return (
    <Card 
      className={`group relative  rounded-xl border-none ${variant.shadow} shadow-lg hover:shadow-xl transition-all duration-300`}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className={`absolute inset-0 ${variant.bg} opacity-70`} />
      <div className="absolute inset-0 bg-gradient-to-br from-white/50 to-transparent" />
      
      {/* Info Tooltip */}
      <div className={`
        absolute -top-12 left-0 right-0 mx-4 p-2 rounded-lg 
        bg-gray-800/95 text-white text-xs

        transform transition-all duration-300 ease-out
        ${showInfo ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-1 pointer-events-none'}
        z-30
      `}>
        {variant.info}
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2">
          <div className="border-solid border-4 border-transparent border-t-gray-800/95" />
        </div>
      </div>

      <CardContent className="relative h-32 p-4 flex flex-col justify-center items-center">
        <div className={`absolute top-0 right-0 w-16 h-1 ${variant.highlight} rounded-bl-lg opacity-80`} />
        
        {/* Info Icon */}
        <div className={`
          absolute top-2 right-2 
          opacity-0 group-hover:opacity-60 
          transition-opacity duration-400
          z-30
        `}>
          <Info className={`w-4 h-4 ${variant.text}`} />
        </div>

        {/* Icon and Content */}
        <div className="flex items-center gap-2 mb-2">
          {getIcon(index)}
          <span className={`text-2xl font-bold ${variant.text}`}>
            {value}
          </span>
        </div>
        <span className={`text-sm font-medium text-center ${variant.text} max-w-[90%]`}>
          {name}
        </span>
      </CardContent>
    </Card>
  );
};

const CompetencyReport = ({ competencyData }: CompetencyReportProps) => {
  if (!competencyData) {
    return (
      <div className="flex justify-center items-center h-24 text-gray-500">
        Loading competency data...
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h4 className="text-xl font-bold text-gray-900">
          Competency Analysis
        </h4>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4">
        {competencyData.map((c, idx) => (
          <CompetencyCard
            key={c.key}
            name={c.key}
            value={c.value}
            index={idx}
          />
        ))}
      </div>
    </div>
  );
}

export default CompetencyReport;
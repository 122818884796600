import React, { useEffect, useState } from "react";
import {
  Button as AntButton,
  Upload,
  message,
  Spin,
  Drawer,
  Select,
} from "antd";
import McqEditor from "../../components/dash-components/Mcq/McqEditor";
import McqViewer from "../../components/dash-components/Mcq/McqViewer";
import { useUploadImageMutation } from "../../app/api/apiSlice";
import { set, uniqueId } from "lodash";
import { useUploadMcqQuestionsMutation } from "../../quiz/CompetitionApiSlice";
import { toast } from "sonner";
import { useGetQuestionsCatalogueQuery } from "../questions/QuestionsApiSlice";
import TopicSelector from "../../components/dash-components/TopicSelector";
import { Button } from "../../ui/button";
import {
  IMCQCaseQuestion,
  IMCQQuestion,
} from "../../components/dash-components/Mcq/types";
import { useGetTopicwiseMcqCountQuery } from "./McqApiSlice";
import { CurriculumBoard } from "../EditTopics/components/type";
import { ThreeDots } from "react-loader-spinner";

const McqManual: React.FC = () => {
  const [questions, setQuestions] = useState<IMCQQuestion[]>([]);
  const [newQuestion, setNewQuestion] = useState<IMCQQuestion | undefined>();
  const [isAdding, setIsAdding] = useState<boolean>(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [editingQuestion, setEditingQuestion] = useState<
    IMCQQuestion | undefined
  >();
  const [curriculumBoard, setCurriculumBoard] = useState<CurriculumBoard | "">(
    CurriculumBoard.ICSE
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [savedisabled, setSavedisabled] = useState<boolean>(true);

  // New states for topicId, subtopicId, and allConceptTags
  const [topicId, setTopicId] = useState<string | null>(null);
  const [subtopicId, setSubtopicId] = useState<string | null>(null);
  const [allConceptTags, setAllConceptTags] = useState<any[]>([]);

  const [uploadAsset] = useUploadImageMutation();
  const [uploadQuestions, isSuccess] = useUploadMcqQuestionsMutation();
  const {
    data: classData,
    isLoading: isClassDataLoading,
    isFetching: isClassDataFetching,
    isSuccess: isClassDataSuccess,
  } = useGetQuestionsCatalogueQuery({
    curriculumBoard: curriculumBoard as CurriculumBoard,
  });

  // Edit an existing question
  const handleEdit = (question: IMCQQuestion) => {
    setEditingQuestion(question);
    setIsEditing(true);
  };

  // Save the new or edited question
  const handleSave = (updatedQuestion: IMCQQuestion) => {
    if (isAdding) {
      setQuestions((prevQuestions) => [
        ...prevQuestions,
        {
          ...updatedQuestion,
        },
      ]);
      setIsAdding(false); // Exit adding mode
    } else {
      setQuestions((prev) =>
        prev.map((q) => (q.id === updatedQuestion.id ? updatedQuestion : q))
      );
    }
    setIsEditing(false);
    setIsAdding(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setIsAdding(false);
  };

  // Function triggered when clicking "Add Question"
  const handleAddQuestion = () => {
    const newQuestion: IMCQQuestion = {
      id: uniqueId(),
      topic: topicId || undefined,
      subtopic: subtopicId || undefined,
      allConceptTags: allConceptTags || [],
      en_question: [],
      en_options: [],
      hi_question: undefined,
      hi_options: undefined,
      conceptTags: [],
      explaination: undefined,
      meta: [],
    };

    // Add the new question to the state
    // setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
    setNewQuestion(newQuestion);
  };

  // Open drawer when newQuestion is added
  useEffect(() => {
    if (
      newQuestion &&
      newQuestion.en_question.length === 0 &&
      newQuestion.en_options.length === 0
    ) {
      setIsAdding(true); // Open drawer for adding new question
    }
  }, [newQuestion]);

  const handleChange = (value: CurriculumBoard | "") => {
    setCurriculumBoard(value);
  };

  // Update filter with TopicSelector and store selected topicId, subtopicId, and allConceptTags
  const updateFilter = (selection: any) => {
    const concepts = selection.concept || null;
    const topicId = selection.topicId || undefined;
    const subtopicId = selection.subtopicId || undefined;

    setTopicId(topicId);
    setSubtopicId(subtopicId);
    setAllConceptTags(concepts);

    setQuestions((prevQuestions: IMCQQuestion[]) =>
      prevQuestions.map((question) => {
        const matchedTags = question.concept
          ?.map((conceptName) => {
            return concepts.find(
              (concept: any) => concept.name === conceptName
            );
          })
          .filter((tag: any) => tag !== undefined);
        const matchedIDs = matchedTags?.map((tag: any) => tag?.id);

        return {
          ...question,
          topic: topicId,
          subtopic: subtopicId,
          allConceptTags: concepts,
          conceptTags: matchedIDs,
        };
      })
    );
    if (subtopicId) {
      setSavedisabled(false);
    } else {
      setSavedisabled(true);
    }
  };

  const onUpload = async () => {
    console.log("Questions before uploading", questions);
    const removedIdQuestion = questions.map(
      ({ id, concept, allConceptTags, ...rest }) => rest
    );
    
    const questionWithCurriculumBoard = removedIdQuestion.map((q) => ({
      ...q,
      curriculumBoard: curriculumBoard as CurriculumBoard,
    }));

    const uploadedQuestions = await uploadQuestions(questionWithCurriculumBoard);
    if (uploadedQuestions.error) {
      toast.error("Error in uploading questions");
    } else {
      toast.success("Questions uploaded successfully!!");
    }
  };

  const uploadAudio = async (formData: FormData) => {
    const result = await uploadAsset({
      formData,
      prefix: "mcq-audio",
    }).unwrap();
    return result.url;
  };

  const uploadImage = async (formData: FormData) => {
    const result = await uploadAsset({
      formData,
      prefix: "mcq-image",
    }).unwrap();
    return result.url;
  };

  return (
    <div className="p-3">
      {/* <div className="flex justify-between">
        {classData && (
          <TopicSelector
            classes={classData}
            onChange={updateFilter}
            preselectedClass={null}
            preselectedTopic={null}
            preselectedSubtopic={null}
          />
        )}
      </div> */}
      <div className="flex">
        <Select
          style={{ width: 200 }}
          value={curriculumBoard}
          onChange={handleChange}
          placeholder="Select Curriculum Board"
          className="mr-2"
        >
          <Select.Option key="all" value="">
            All
          </Select.Option>
          {Object.values(CurriculumBoard).map((board) => (
            <Select.Option key={board} value={board}>
              {board}
            </Select.Option>
          ))}
        </Select>
        <div className="relative">
          {classData && isClassDataSuccess && !isClassDataFetching ? (
            <TopicSelector classes={classData || []} onChange={updateFilter} />
          ) : (
            <div className="flex justify-center items-center bg-white rounded-lg ">
              {/* <Spin indicator={antIcon} tip="Loading class data..." /> */}
              <ThreeDots
                height="40"
                width="80"
                radius="9"
                color="#1890ff"
                ariaLabel="three-dots-loading"
                visible={true}
              />
            </div>
          )}
        </div>
      </div>
      <AntButton
        type="dashed"
        disabled={savedisabled}
        className="  ml- mb-4 mt-3"
        onClick={handleAddQuestion}
      >
        + Add Question
      </AntButton>
      {loading ? (
        <Spin tip="Uploading and Parsing..." />
      ) : isAdding || isEditing ? (
        <Drawer
          title={isAdding ? "Add New Question" : "Edit Question"}
          open={isAdding || isEditing}
          width={"90%"}
          onClose={handleCancel}
          destroyOnClose
        >
          <McqEditor
            data={isAdding ? newQuestion : editingQuestion}
            onSave={handleSave}
            uploadAudio={uploadAudio}
            uploadImage={uploadImage}
          />
        </Drawer>
      ) : (
        <div className="mt-3">
          {questions.map(
            (question) =>
              question.en_question.length > 0 &&
              question.en_options.length > 0 && (
                <McqViewer
                  key={question.id}
                  question={question}
                  onEdit={() => handleEdit(question)}
                  setSavedisabled={setSavedisabled}
                />
              )
          )}
          <Button
            disabled={
              savedisabled ||
              (isSuccess && isSuccess.status === "fulfilled") ||
              questions.length === 0
            }
            onClick={onUpload}
            className="bg-[#a42fc6] text-white ml-2 mb-4"
          >
            Save
          </Button>
        </div>
      )}
    </div>
  );
};

export default McqManual;

import React from 'react';
import { Avatar, AvatarImage, AvatarFallback } from "../../../ui/avatar";
import { Card, CardContent } from "../../../ui/card";
import { GraduationCap, TrendingUp, BookOpen, BrainCircuit, Target, AlertCircle, Trophy, Flame, Coins, Diamond } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../ui/tooltip";

type ProfileSummaryProps = {
  profileData?: {
    name: string;
    grade: string;
    rank: number;
    gradeMastery: number;
    fln: number;
    learningGaps: number;
    completionRate: number;
    streak: number;
    coins: number;
    gems: number;
  };
};

// const dummyData = {
//   name: "John Doe",
//   grade: "8",
//   rank: 256,
//   gradeMastery: 85,
//   fln: 92,
//   learningGaps: 3,
//   completionRate: 78,
//   streak: 12,
//   coins: 1450,
//   gems: 75
// };

function ProfileSummary({ profileData }: ProfileSummaryProps) {
  const stats = [
    {
      title: "Class Rank",
      value: profileData?.rank ? `#${profileData.rank}` : '#0',
      icon: <Trophy className="w-4 h-4" />,
      color: "text-yellow-600",
      bgColor: "bg-yellow-50",
      description: "Student's position in class based on their grade-level mastery performance only"
    },
    {
      title: "Grade Mastery",
      value: `${profileData?.gradeMastery || 0}%`,
      icon: <GraduationCap className="w-4 h-4" />,
      color: "text-blue-600",
      bgColor: "bg-blue-50",
      description: "Student's current mastery level in grade-appropriate concepts, indicating their understanding of this year's curriculum."
    },
    {
      title: "Foundation Mastery",
      value: `${profileData?.fln || 0}%`,
      icon: <BookOpen className="w-4 h-4" />,
      color: "text-green-600",
      bgColor: "bg-green-50",
      description: "Student's mastery of fundamental concepts from previous grades that are essential for current learning."
    },
    {
      title: "Critical Learning Gaps",
      value: profileData?.learningGaps || 0,
      icon: <AlertCircle className="w-4 h-4" />,
      color: "text-red-600",
      bgColor: "bg-red-50",
      description: "Number of essential concepts where significant understanding gaps have been identified."
    },
    // {
    //   title: "Completion Rate",
    //   value: `${profileData?.completionRate || 0}%`,
    //   icon: <PieChart className="w-4 h-4" />,
    //   color: "text-purple-600",
    //   bgColor: "bg-purple-50",
    //   description: "Percentage of assigned learning activities completed by the student, showing their engagement with recommendations."
    // }
  ];

  const rewardTags = [
    {
      icon: <Flame className="w-4 h-4 text-orange-500" />,
      value: profileData?.streak || 0,
      tooltip: "Current learning streak"
    },
    {
      icon: <Coins className="w-4 h-4 text-yellow-500" />,
      value: profileData?.coins || "0",
      tooltip: "Total coins earned"
    },
    {
      icon: <Diamond className="w-4 h-4 text-blue-500" />,
      value: profileData?.gems || "0",
      tooltip: "Total gems collected"
    }
  ];

  return (
    <Card className="border-0">
      <CardContent className="p-6 space-y-6">
        <div className="flex items-center gap-6">
          <Avatar className="h-16 w-16 ring-2 ring-primary/10">
            <AvatarFallback className="bg-gradient-to-br from-blue-500 to-indigo-600 text-lg text-white font-medium">
              {profileData?.name
                ? profileData.name
                    .split(" ")
                    .map((n) => n[0])
                    .join("")
                : "U"}
            </AvatarFallback>
          </Avatar>
          
          <div className="flex-1">
            <div className="flex items-center justify-between">
              <div>
                <h2 className="text-2xl font-bold bg-gradient-to-r from-primary to-primary/70 bg-clip-text text-transparent">
                  {profileData?.name || "User"}
                </h2>
                <div className="flex items-center gap-2 text-sm text-blue-600/80 font-medium mt-1">
                  <GraduationCap className="w-4 h-4" />
                  Grade {profileData?.grade || "0"}
                </div>
              </div>
              
              <div className="flex gap-3">
                {rewardTags?.map((tag, index) => (
                  <TooltipProvider delayDuration={200} key={index}>
                    <Tooltip>
                      <TooltipTrigger asChild>
                        <div className="flex cursor-help items-center gap-1.5 px-3 py-1.5 bg-purple-50 rounded-full">
                          {tag.icon}
                          <span className="text-sm font-medium text-purple-700">{tag.value}</span>
                        </div>
                      </TooltipTrigger>
                      <TooltipContent className="bg-white border border-gray-200">
                        <p className="text-sm text-gray-600">{tag.tooltip}</p>
                      </TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {stats?.map((stat, index) => (
            <TooltipProvider delayDuration={200} key={index}>
              <Tooltip>
                <TooltipTrigger asChild>
                  <div className="bg-white rounded-lg p-3 shadow-sm hover:bg-gray-50/50 cursor-help">
                    <div className="flex items-center justify-between mb-2">
                      <div className={`${stat?.bgColor} ${stat?.color} p-1.5 rounded-lg`}>
                        {stat?.icon}
                      </div>
                      <span className={`text-xl font-bold ${stat?.color}`}>
                        {stat?.value}
                      </span>
                    </div>
                    <p className="text-xs text-gray-600 font-medium">
                      {stat?.title}
                    </p>
                  </div>
                </TooltipTrigger>
                <TooltipContent className="bg-white border border-gray-200 max-w-xs break-words">
                  <p className="text-sm text-gray-600">{stat?.description}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          ))}
        </div>
      </CardContent>
    </Card>
  );
}

export default ProfileSummary;









// import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
// import { Card } from "@/components/ui/card";
// import { Badge } from "@/components/ui/badge";
// import InfoCard from "./InfoCard";
// import { GraduationCap, Mail, Phone, MapPin, Calendar } from "lucide-react";
// import { format } from "date-fns";
// import GradeMastery from "../../../assets/GradeMastery.png";
// import FNL from "../../../assets/FNL.png";
// import PriorLearningIndex from "../../../assets/PriorLearningIndex.png";
// import TotalScore from "../../../assets/TotalScore.png";

// type ProfileSummaryProps = {
//   profileData: {
//     name: string;
//     grade: string;
//     gradeMastery: number;
//     priorLearningIndex: number;
//     fln: number;
//     averageScore: number;
//     email?: string;
//     phone?: string;
//     address?: string;
//     dateOfBirth?: string;
//     id?: string;
//     status?: string;
//   };
// };

// function ProfileSummary({ profileData }: ProfileSummaryProps) {
//   return (
//     <Card className="w-full bg-gradient-to-br from-white to-slate-50 rounded-xl overflow-hidden">
//       <div className="flex flex-col lg:flex-row gap-8 p-6">
//         <div className="flex flex-col items-center lg:items-start space-y-4 lg:w-1/3">
//           <div className="relative">
//             <Avatar className="h-32 w-32 md:h-48 md:w-48 ring-4 ring-primary/20 shadow-lg">
//               <AvatarImage
//                 src={`https://ui-avatars.com/api/?name=${profileData?.name}&background=0D8ABC&color=fff`}
//               />
//               <AvatarFallback className="bg-gradient-to-br from-blue-500 to-indigo-600 text-2xl text-white font-medium">
//                 {profileData?.name
//                   ?.split(" ")
//                   .map((n) => n[0])
//                   .join("")}
//               </AvatarFallback>
//             </Avatar>
//           </div>
//           <div className="space-y-3 flex-1">
//             <div>
//               <h2 className="text-2xl md:text-3xl font-bold bg-gradient-to-r from-primary to-primary/70 bg-clip-text text-transparent">
//                 {profileData?.name}
//               </h2>
//               <div className="flex items-center gap-2 text-sm text-blue-600 font-medium mt-1">
//                 <GraduationCap className="w-4 h-4" />
//                 Grade {profileData?.grade || ""}
//               </div>
//             </div>
//           </div>
//         </div>

//         {/* Stats Grid */}
//         <div className="lg:w-2/3">
//           <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 md:gap-6">
//             <InfoCard
//               title={profileData?.gradeMastery.toString() || "0"}
//               subtitle="Grade Mastery"
//               leftBgColor="from-blue-100 to-blue-50"
//               iconImgSrc={GradeMastery}
//               message="Indicates student's proficiency level in current grade curriculum objectives and learning outcomes."
//             />
//             <InfoCard
//               title={profileData?.priorLearningIndex.toString() || "0"}
//               subtitle="Prior Learning Index"
//               leftBgColor="from-purple-100 to-purple-50"
//               iconImgSrc={PriorLearningIndex}
//               message="Measures student's grasp of prerequisite concepts from previous grades essential for current learning progression."
//             />
//             <InfoCard
//               title={profileData?.fln.toString() || "0"}
//               subtitle="Learning Gaps"
//               leftBgColor="from-green-100 to-green-50"
//               iconImgSrc={FNL}
//               message="Assessment of student's core literacy and numeracy skills as per age-appropriate foundational learning standards."
//             />
//             <InfoCard
//               title={profileData?.averageScore.toString() || "0"}
//               subtitle="Accuracy"
//               leftBgColor="from-orange-100 to-orange-50"
//               iconImgSrc={TotalScore}
//               message="Cumulative performance metric calculated from all assessments and evaluations across subjects."
//             />
//           </div>
//         </div>
//       </div>
//     </Card>
//   );
// }

// export default ProfileSummary;


// import { Avatar, AvatarImage, AvatarFallback } from "@/components/ui/avatar";
// import { Card, CardContent } from "@/components/ui/card";
// import { GraduationCap, TrendingUp, BookOpen, BrainCircuit, Target } from "lucide-react";
// import {
//   Tooltip,
//   TooltipContent,
//   TooltipProvider,
//   TooltipTrigger,
// } from "@/components/ui/tooltip";

// type ProfileSummaryProps = {
//   profileData: {
//     name: string;
//     grade: string;
//     gradeMastery: number;
//     priorLearningIndex: number;
//     fln: number;
//     averageScore: number;
//     email?: string;
//     phone?: string;
//     address?: string;
//     dateOfBirth?: string;
//     id?: string;
//     status?: string;
//   };
// };

// function ProfileSummary({ profileData }: ProfileSummaryProps) {
//   const stats = [
//     {
//       title: "Grade Mastery",
//       value: profileData?.gradeMastery || 0,
//       icon: <GraduationCap className="w-4 h-4" />,
//       color: "text-blue-600",
//       bgColor: "bg-blue-50",
//       tooltip: "Indicates student's proficiency level in current grade curriculum objectives"
//     },
//     {
//       title: "Prior Learning",
//       value: profileData?.priorLearningIndex || 0,
//       icon: <BookOpen className="w-4 h-4" />,
//       color: "text-purple-600",
//       bgColor: "bg-purple-50",
//       tooltip: "Measures grasp of prerequisite concepts from previous grades"
//     },
//     {
//       title: "Learning Gaps",
//       value: profileData?.fln || 0,
//       icon: <BrainCircuit className="w-4 h-4" />,
//       color: "text-green-600",
//       bgColor: "bg-green-50",
//       tooltip: "Assessment of core literacy and numeracy skills"
//     },
//     {
//       title: "Accuracy",
//       value: profileData?.averageScore || 0,
//       icon: <Target className="w-4 h-4" />,
//       color: "text-orange-600",
//       bgColor: "bg-orange-50",
//       tooltip: "Cumulative performance metric across all assessments"
//     }
//   ];

//   return (
//     <div className="space-y-4">
//       <Card className="border-0 shadow-sm">
//         <CardContent className="p-4">
//           <div className="flex items-center gap-6">
//             <Avatar className="h-16 w-16 ring-2 ring-primary/10">
//               <AvatarImage
//                 src={`https://ui-avatars.com/api/?name=${profileData?.name}&background=0D8ABC&color=fff`}
//               />
//               <AvatarFallback className="bg-gradient-to-br from-blue-500 to-indigo-600 text-lg text-white font-medium">
//                 {profileData?.name
//                   ?.split(" ")
//                   .map((n) => n[0])
//                   .join("")}
//               </AvatarFallback>
//             </Avatar>
            
//             <div className="flex-1">
//               <div className="flex items-center justify-between">
//                 <div>
//                   <h2 className="text-2xl font-bold bg-gradient-to-r from-primary to-primary/70 bg-clip-text text-transparent">
//                     {profileData?.name}
//                   </h2>
//                   <div className="flex items-center gap-2 text-sm text-blue-600/80 font-medium mt-1">
//                     <GraduationCap className="w-4 h-4" />
//                     Grade {profileData?.grade || ""}
//                   </div>
//                 </div>
//                 <TrendingUp className="w-5 h-5 text-blue-500" />
//               </div>
//             </div>
//           </div>
//         </CardContent>
//       </Card>

//       <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
//         {stats.map((stat, index) => (
//           <TooltipProvider key={index}>
//             <Tooltip>
//               <TooltipTrigger asChild>
//                 <Card className="border-0 shadow-sm hover:shadow-md transition-shadow duration-200">
//                   <CardContent className="p-4">
//                     <div className="flex items-center justify-between mb-2">
//                       <div className={`${stat.bgColor} ${stat.color} p-2 rounded-lg`}>
//                         {stat.icon}
//                       </div>
//                       <span className={`text-2xl font-bold ${stat.color}`}>
//                         {stat.value}
//                       </span>
//                     </div>
//                     <p className="text-sm text-gray-600 font-medium">
//                       {stat.title}
//                     </p>
//                   </CardContent>
//                 </Card>
//               </TooltipTrigger>
//               <TooltipContent>
//                 <p className="max-w-xs">{stat.tooltip}</p>
//               </TooltipContent>
//             </Tooltip>
//           </TooltipProvider>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default ProfileSummary;










import { useMemo } from "react";

interface TileConfigOptions {
  tileSize?: number;
  tileOffset?: number;
  maxTilesPerRow?: number;
}

const DEFAULT_CONFIG = {
  tileSize: 50,
  tileOffset: 50,
  maxTilesPerRow: 5,
} as const;

export const useTileConfig = (
  totalTiles: number,
  options: TileConfigOptions = {}
) => {
  return useMemo(() => {
    const config = { ...DEFAULT_CONFIG, ...options };
    const positions = [];

    let currentTile = 0;
    let row = 0;

    while (currentTile < totalTiles) {
      const tilesInRow = Math.min(row + 2, totalTiles - currentTile);
      for (let col = 0; col < tilesInRow; col++) {
        positions.push({
          top: row * config.tileSize * 0.75,
          left:
            col * config.tileOffset +
            ((config.maxTilesPerRow - tilesInRow) * config.tileOffset) / 2,
        });
        currentTile++;
      }
      row++;
    }

    const maxTilesInRow = Math.min(
      config.maxTilesPerRow,
      Math.max(...Array.from({ length: row }, (_, i) => i + 2))
    );

    return {
      tilePositions: positions,
      containerStyle: {
        width: maxTilesInRow * config.tileOffset,
        height: row * config.tileSize * 0.75 + config.tileSize + 40,
      },
    };
  }, [totalTiles, options]);
};

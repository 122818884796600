import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../ui/Dialog";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../ui/table";

type WeakConceptsDialogProps = {
  weakConcepts: any[];
  onClose: () => void;
};

export const WeakConceptsDialog = ({
  weakConcepts,
  onClose,
}: WeakConceptsDialogProps) => {
  return (
    <Dialog open={weakConcepts.length > 0} onOpenChange={onClose}>
      <DialogContent className=" w-[800px] max-w-full">
        <DialogHeader>
          <DialogTitle>Weak Concepts</DialogTitle>
        </DialogHeader>
        <Table>
  <TableHeader>
    <TableRow>
      <TableHead className="text-left px-4 py-2">Concept</TableHead>
      <TableHead className="text-left px-4 py-2">Chapter</TableHead>
      <TableHead className="text-left px-4 py-2">Comprehension</TableHead>
      <TableHead className="text-left px-4 py-2">Memory</TableHead>
      <TableHead className="text-left px-4 py-2">HOTS</TableHead>
      <TableHead className="text-left px-4 py-2">Mastery</TableHead>
    </TableRow>
  </TableHeader>
  <TableBody>
    {weakConcepts.map((concept, i) => (
      <TableRow key={i}>
        <TableCell className="text-left px-4 py-2">{concept.conceptName}</TableCell>
        <TableCell className="text-left px-4 py-2">{concept.chapterName}</TableCell>
        <TableCell className="text-left px-4 py-2">{concept.comprehensionMastery}</TableCell>
        <TableCell className="text-left px-4 py-2">{concept.memoryMastery}</TableCell>
        <TableCell className="text-left px-4 py-2">{concept.hots}</TableCell>
        <TableCell className="text-left px-4 py-2">{concept.mastery}</TableCell>
      </TableRow>
    ))}
  </TableBody>
</Table>

      </DialogContent>
    </Dialog>
  );
};

import { Card } from "../../../ui/card";
import { Col, Row } from "antd";
import { BookCheck, Brain, Clock, Target } from "lucide-react";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  RadarChart,
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  BarChart,
  Bar,
} from "recharts";

type StatCardProps = {
  value: number | string;
  label: string;
  colorScheme: {
    from: string;
    to: string;
    hoverFrom: string;
    hoverTo: string;
    text: string;
  };
};

// function StatCard({ value, label, colorScheme }: StatCardProps) {
//   return (
//     <Card
//       className={`flex flex-col items-center min-h-[100px] justify-center bg-gradient-to-br from-${colorScheme.from} to-${colorScheme.to} hover:from-${colorScheme.hoverFrom} hover:to-${colorScheme.hoverTo} transition-all transform hover:shadow-lg hover:scale-105`}
//     >
//       <span className={`text-3xl font-bold text-${colorScheme.text}`}>
//         {value}
//       </span>
//       <span className={`text-sm font-medium text-${colorScheme.text}`}>
//         {label}
//       </span>
//     </Card>
//   );
// }



function StatCard({ value, label, colorScheme }: StatCardProps) {
  const getIcon = (label: string) => {
    switch (label) {
      case "Worksheets Completed":
        return <BookCheck className="w-6 h-6" />;
      case "Questions Solved":
        return <Brain className="w-6 h-6" />;
      case "Minutes Engaged":
        return <Clock className="w-6 h-6" />;
      case "Submission Rate":
        return <Target className="w-6 h-6" />;
      default:
        return null;
    }
  };

  return (
    <Card className="relative overflow-hidden group">
      <div className={`absolute inset-0 bg-gradient-to-br from-${colorScheme.from}/40 to-${colorScheme.to}/40 opacity-50 group-hover:opacity-100 transition-opacity duration-300`} />
      <div className="relative p-6 flex items-center">
        <div className="flex-1">
          <div className={`text-3xl font-bold mb-1 text-${colorScheme.text}`}>
            {value}
          </div>
          <div className={`text-sm font-medium text-${colorScheme.text}/80`}>
            {label}
          </div>
        </div>
        <div className={`text-${colorScheme.text} opacity-70 group-hover:opacity-100 transition-opacity duration-300`}>
          {getIcon(label)}
        </div>
      </div>
      <div className={`h-1 w-full bg-gradient-to-r from-${colorScheme.from} to-${colorScheme.to} group-hover:from-${colorScheme.hoverFrom} group-hover:to-${colorScheme.hoverTo} transition-colors duration-300`} />
    </Card>
  );
}

type EngagementStatusProps = {
  engagementData?: {
    totalWorksheetsCompleted: number;
    timeSpent: number;
    totalQuestionsAnswered: number;
    completionRate: number;
    questionAttemptedTrend: { date: string; value: number }[];
    courseWiseCompletion: { subject: string; completion: number }[];
  };
};

function EngagementStatus({ engagementData }: EngagementStatusProps) {
  if (!engagementData) {
    return <div></div>;
  }

  const statCards = [
    {
      value: engagementData.totalWorksheetsCompleted,
      label: "Worksheets Completed",
      colorScheme: {
        from: "cyan-50",
        to: "cyan-100",
        hoverFrom: "cyan-100",
        hoverTo: "cyan-200",
        text: "cyan-700",
      },
    },
    {
      value: engagementData.totalQuestionsAnswered,
      label: "Questions Solved",
      colorScheme: {
        from: "emerald-50",
        to: "emerald-100",
        hoverFrom: "emerald-100",
        hoverTo: "emerald-200",
        text: "emerald-700",
      },
    },
    {
      value: engagementData.timeSpent,
      label: "Minutes Engaged",
      colorScheme: {
        from: "orange-50",
        to: "orange-100",
        hoverFrom: "orange-100",
        hoverTo: "orange-200",
        text: "orange-700",
      },
    },
    {
      value: `${engagementData.completionRate}%`,
      label: "Submission Rate",
      colorScheme: {
        from: "indigo-50",
        to: "indigo-100",
        hoverFrom: "indigo-100",
        hoverTo: "indigo-200",
        text: "indigo-700",
      },
    },
  ];
  return (
    <div className="space-y-8">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
        {statCards.map((card, i) => (
          <StatCard key={i} {...card} />
        ))}
      </div>

      <div
        className={`grid grid-cols-1 ${
          engagementData?.courseWiseCompletion?.length > 8
            ? "md:grid-cols-1"
            : "md:grid-cols-2"
        } gap-6`}
      >
        <Card className="p-4 border border-slate-200 min-h-[300px]">
          {!engagementData?.courseWiseCompletion?.length ? (
            <div className="h-full flex items-center justify-center text-gray-500">
              No subject completion data available
            </div>
          ) : engagementData.courseWiseCompletion.length > 8 ? (
            <ResponsiveContainer width="100%" height={300}>
              <BarChart data={engagementData.courseWiseCompletion}>
                <CartesianGrid strokeDasharray="3 3" stroke="#e5e7eb" />
                <XAxis dataKey="subject" tick={{ fill: "#4b5563" }} />
                <YAxis tick={{ fill: "#4b5563" }} />
                <Tooltip
                  contentStyle={{
                    borderRadius: "8px",
                    boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
                  }}
                />
                <Bar
                  dataKey="completion"
                  fill="#0d9488"
                  name="Subject Mastery"
                />
                <Legend wrapperStyle={{ fontSize: "12px", fontWeight: 500 }} />
              </BarChart>
            </ResponsiveContainer>
          ) : (
            <ResponsiveContainer width="100%" height={300}>
              <RadarChart
                data={engagementData?.courseWiseCompletion}
                className="mx-auto"
              >
                <PolarGrid stroke="#e5e7eb" />
                <PolarAngleAxis dataKey="subject" tick={{ fill: "#4b5563" }} />
                <PolarRadiusAxis
                  angle={30}
                  domain={[0, 100]}
                  tick={{ fill: "#4b5563" }}
                />
                <Radar
                  name="Subject Mastery"
                  dataKey="completion"
                  stroke="#0d9488"
                  fill="#5eead4"
                  fillOpacity={0.7}
                />
                <Legend wrapperStyle={{ fontSize: "12px", fontWeight: 500 }} />
                <Tooltip
                  contentStyle={{
                    borderRadius: "8px",
                    boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
                  }}
                />
              </RadarChart>
            </ResponsiveContainer>
          )}
        </Card>

        <Card className="p-4 border border-slate-200 min-h-[300px]">
          <div className="text-sm font-medium text-gray-600 mb-2">
            Questions Attempted Over Time
          </div>
          {!engagementData?.questionAttemptedTrend?.length ? (
            <div className="h-[250px] flex items-center justify-center text-gray-500">
              No attempt history available
            </div>
          ) : (
            <ResponsiveContainer width="100%" height={250}>
              <AreaChart data={engagementData.questionAttemptedTrend}>
                <CartesianGrid strokeDasharray="3 3" stroke="#e5e7eb" />
                <XAxis dataKey="date" tick={{ fill: "#4b5563" }} />
                <YAxis tick={{ fill: "#4b5563" }} />
                <Tooltip
                  contentStyle={{
                    borderRadius: "8px",
                    boxShadow: "0 2px 8px rgba(0,0,0,0.15)",
                  }}
                />
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#6366f1"
                  fill="url(#colorGradient)"
                  fillOpacity={0.8}
                />
                <defs>
                  <linearGradient
                    id="colorGradient"
                    x1="0"
                    y1="0"
                    x2="0"
                    y2="1"
                  >
                    <stop offset="5%" stopColor="#6366f1" stopOpacity={0.8} />
                    <stop offset="95%" stopColor="#6366f1" stopOpacity={0.1} />
                  </linearGradient>
                </defs>
              </AreaChart>
            </ResponsiveContainer>
          )}
        </Card>
      </div>
    </div>
  );
}

export default EngagementStatus;

import React, { useState, useEffect } from "react";
import { Select, Space } from "antd";
import { SelectValue } from "antd/es/select";

interface Concept {
  id: string;
  name: string;
}

interface Subtopic {
  id: string;
  name: string;
  concepts: Concept[];
}

interface Topic {
  id: string;
  name: string;
  subtopics: Subtopic[];
}

export interface ClassData {
  id: string;
  name: string;
  topics: Topic[];
}

interface MultiLevelSelectorProps {
  classes: ClassData[];
  onChange: (selection: {
    classId: string | null;
    topicId: string | null;
    subtopics: Subtopic[];  // Changed to return full subtopics array
    concept: Concept[];
    grade?: string;
  }) => void;
  preselectedClass?: string | null;
  preselectedTopic?: string | null;
  activeCaseIndex?: number;
}

const ProfilingSelector: React.FC<MultiLevelSelectorProps> = ({
  classes,
  onChange,
  preselectedClass = null,
  preselectedTopic = null,
  activeCaseIndex,
}) => {
  const [selectedClass, setSelectedClass] = useState<string | null>(preselectedClass);
  const [selectedTopic, setSelectedTopic] = useState<string | null>(preselectedTopic);
  const [selectedGrade, setSelectedGrade] = useState<string>();

  const selectedClassObj = classes.find((c) => c.id === selectedClass);
  const selectedTopicObj = selectedClassObj?.topics.find((t) => t.id === selectedTopic);

  const handlePresetValues = () => {
    const matchedClass = classes.find((cls) => cls.id === preselectedClass);
    if (matchedClass) {
      const matchedTopic = matchedClass.topics.find(
        (topic) => topic.id === preselectedTopic
      );
      if (matchedTopic) {
        setSelectedClass(matchedClass.id);
        setSelectedTopic(matchedTopic.id);
      }
    }
  };

  useEffect(() => {
    if (preselectedClass || preselectedTopic) {
      handlePresetValues();
    }
  }, [preselectedClass, preselectedTopic]);

  useEffect(() => {
    if (selectedTopicObj) {
      onChange({
        classId: selectedClass,
        topicId: selectedTopic,
        subtopics: selectedTopicObj.subtopics, // Return full subtopics array
        concept: selectedTopicObj.subtopics.flatMap(subtopic => subtopic.concepts),
        grade: selectedGrade,
      });
    } else {
      onChange({
        classId: selectedClass,
        topicId: selectedTopic,
        subtopics: [], // Empty array when no topic selected
        concept: [],
        grade: selectedGrade,
      });
    }
  }, [selectedClass, selectedTopic, activeCaseIndex]);

  const handleClassChange = (value: SelectValue) => {
    const selectedClassObject = classes.find((c) => c.id === value);
    setSelectedGrade(selectedClassObject?.name);
    setSelectedClass(value as string);
    setSelectedTopic(null);
  };

  const handleTopicChange = (value: SelectValue) => {
    setSelectedTopic(value as string);
  };

  return (
    <Space size="large">
      {/* Class Selection */}
      <Select
        style={{ width: 200 }}
        placeholder="Select Class"
        value={selectedClass}
        onChange={handleClassChange}
      >
        {classes.map((c) => (
          <Select.Option key={c.id} value={c.id}>
            {c.name}
          </Select.Option>
        ))}
      </Select>

      {/* Topic Selection */}
      <Select
        style={{ width: 200 }}
        placeholder="Select Subject"
        value={selectedTopic}
        onChange={handleTopicChange}
        disabled={!selectedClassObj}
      >
        {selectedClassObj?.topics.map((t) => (
          <Select.Option key={t.id} value={t.id}>
            {t.name}
          </Select.Option>
        ))}
      </Select>
    </Space>
  );
};

export default ProfilingSelector;
import React, { useCallback } from 'react';
import { TreeSelect } from 'antd';

export interface Concept {
  id: string;
  name: string;
}

interface Subtopic {
  id: string;
  name: string;
  isOpen: boolean;
  concepts: Concept[];
}

export const SubtopicTreeSelect: React.FC<{
  chapters?: any[];
  value?: string[]; // This will store concept names
  onChange?: (selectedConcepts: Concept[]) => void;
}> = ({ chapters, value, onChange }) => {
  const getTreeData = () => {
    return chapters?.map((subtopic) => ({
      title: subtopic.name,
      value: `subtopic-${subtopic.id}`, // Prefix to distinguish from concepts
      key: subtopic.id,
      children: subtopic.concepts.map((concept:any) => ({
        title: concept.name,
        value: concept.name, // Use concept name as value for selection
        key: concept.id,
      })),
    }));
  };

  const handleChange = useCallback((selectedValues: string[]) => {
    // Filter out subtopic selections (those with 'subtopic-' prefix)
    const selectedConceptNames = selectedValues.filter(
      value => !value.startsWith('subtopic-')
    );
    
    // Find complete concept objects from all chapters
      const selectedConcepts = selectedConceptNames.map(conceptName => {
        if(!chapters) return null;
      for (const chapter of chapters) {
        const concept = chapter.concepts.find((c:any) => c.name === conceptName);
        if (concept) {
          return concept;
        }
      }
      return null;
    }).filter((concept): concept is Concept => concept !== null);
    
    // Call the parent's onChange with complete concept objects
    onChange?.(selectedConcepts);
  }, [chapters, onChange]);

  return (
    <TreeSelect
      treeData={getTreeData()}
      style={{ width: '100%' }}
      value={value}
      onChange={handleChange}
      treeCheckable
      showSearch
      placeholder="Please select chapters and concepts"
      allowClear
      multiple
    />
  );
};